import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { LightingControlTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.LIGHTING_CONTROL,
  LightingControlTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [LightingControlTypes.DIMMER]: t("Dimmer"),
  [LightingControlTypes.SENSOR]: t("Sensor"),
  [LightingControlTypes.CONTROL_PANEL]: t("Control Panel"),
  [LightingControlTypes.SWITCH]: t("Switch"),
  [LightingControlTypes.PULL_CHORD]: t("Pull Chord"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [LightingControlTypes.DIMMER]: t("Dimmer"),
  [LightingControlTypes.SENSOR]: t("Sensor"),
  [LightingControlTypes.CONTROL_PANEL]: t("Control Panel"),
  [LightingControlTypes.SWITCH]: t("Switch"),
  [LightingControlTypes.PULL_CHORD]: t("Pull Chord"),
});

const typeDescriptions: Config["typeDescriptions"] = (t) => ({
  [LightingControlTypes.DIMMER]: t(
    "A dimmer switch allows you to adjust the brightness of a connected light fixture, offering greater control over ambiance and energy usage. It comes in various styles, such as rotary, slide, or touch-sensitive designs."
  ),
  [LightingControlTypes.SENSOR]: t(
    "A lighting sensor automatically controls lights based on factors like motion, occupancy, or ambient light levels. It enhances convenience and energy efficiency by activating lights only when needed."
  ),
  [LightingControlTypes.CONTROL_PANEL]: t(
    "A control panel is a centralized interface that manages multiple electrical or mechanical systems, such as lighting, HVAC, or security. It often includes switches, buttons, or touchscreens for streamlined operation."
  ),
  [LightingControlTypes.SWITCH]: t(
    "A light switch is a simple device that controls the on/off functionality of a connected light fixture. Typically mounted on walls, it uses a basic toggle or rocker mechanism for quick and reliable operation."
  ),
  [LightingControlTypes.PULL_CHORD]: "",
});

export const LightingControlsConfigTexts: Config = {
  itemName: (t) => t("Lighting Control"),
  categoryName: (t) => t("Lighting Controls"),
  categoryDescription: (t) =>
    t(
      "Lighting controls are tools that help manage how bright or dim lights are in different spaces. They come in various types, like dimmer switches that adjust brightness, timers that turn lights on/off automatically, and smart systems that can be controlled with phones or voice commands. These controls are useful for creating the right atmosphere, saving energy, and making lighting more convenient."
    ),
  itemNames,
  typeNames,
  typeDescriptions,
};
