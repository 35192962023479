import { DropShadowFilterOptions } from "@pixi/filter-drop-shadow";
import { to0xHex } from "common/utils/colors";
import { Graphics } from "pixi.js";
import colors from "styles/_jscolors.module.scss";
import { colorPalette } from "styles/themes/homebase_theme";

export const AREA_FILL_COLOR = to0xHex(colors.colorWhite);
export const WALL_COLOR = to0xHex(colors.colorFloorPlanWall);
export const INACTIVE_WALL_COLOR = to0xHex(colors.colorGrey300);
export const INACTIVE_AREA_FILL_COLOR: Parameters<Graphics["beginFill"]> = [
  0, 0,
];

export const WALL_COLOR_THIN = to0xHex(colors.colorFloorPlanWallThin);
export const WALL_COLOR_THIN_HOVER = to0xHex(
  colors.colorFloorPlanWallThinHover
);
export const WALL_COLOR_THIN_ERROR = to0xHex(
  colors.colorFloorPlanWallThinError
);

export const CORNER_MARKER_COLOR = to0xHex(colors.mainBlue);
export const CORNER_MARKER_COLOR_HOVER = to0xHex(
  colors.colorFloorPlanWallThinHover
);
export const CORNER_MARKER_COLOR_ERROR = to0xHex(
  colors.colorFloorPlanWallThinError
);
export const CORNER_MARKER_COLOR_STROKE = to0xHex(colors.colorWhite);

export const CORNER_VIRTUAL_WALL_COLOR = to0xHex(
  colors.colorFloorPlanVirtualWall
);

export const WALL_ELEVATION_OPTIONS: Partial<DropShadowFilterOptions> = {
  color: to0xHex(colors.colorElevation2),
  alpha: 0.15,
  blur: 4,
  quality: 3,
};

export const getWallArrowColor = () => to0xHex(colorPalette.colorBlue);

export const WINDOW_COLOR = to0xHex(colors.mainBlue);
export const WINDOW_BASE_COLOR = to0xHex(colors.colorWhite);
export const GRID_BG_COLOR = to0xHex(colors.colorGrey200);
export const GRID_LINE_COLOR = to0xHex(colors.colorGrey400);
export const HALF_WALL_PATTERN_LINE_COLOR = to0xHex(
  colors.colorHalfWallPatternLine
);
export const ALIGN_LINE_COLOR = to0xHex(colors.colorFloorPlanWallThin);
export const DISTANCEHELPER_LINE_COLOR = to0xHex(colors.colorFloorPlanWallThin);

export const FLOOR_PLAN_ITEM_GRAPHIC_ACTIVE_BORDER_COLOR = to0xHex(
  colors.colorFloorPlanItemGraphicActiveBorder
);
export const FLOOR_PLAN_ITEM_GRAPHIC_ACTIVE_COLOR = to0xHex(
  colors.colorFloorPlanItemGraphicActive
);
export const FLOOR_PLAN_ITEM_GRAPHIC_INACTIVE_BORDER_COLOR = to0xHex(
  colors.colorFloorPlanItemGraphicInactiveBorder
);
export const FLOOR_PLAN_ITEM_GRAPHIC_INACTIVE_COLOR = to0xHex(
  colors.colorFloorPlanItemGraphicInactive
);
export const CEILING_FAN_GRAPHIC_BORDER_COLOR = to0xHex(colors.colorWhite);
export const CEILING_FAN_GRAPHIC_SEGMENT_ACTIVE_COLOR = to0xHex(
  colors.colorCeilingFanSegmentActive
);
export const CEILING_FAN_GRAPHIC_SEGMENT_INACTIVE_COLOR = to0xHex(
  colors.colorCeilingFanSegmentInActive
);
export const CEILING_FAN_GRAPHIC_STANDARD_CENTER_ACTIVE_COLOR = to0xHex(
  colors.colorFloorPlanItemGraphicActiveBorder
);
export const CEILING_FAN_GRAPHIC_WITHLIGHT_CENTER_ACTIVE_COLOR = to0xHex(
  colors.colorFloorPlanItemGraphicActive
);
export const CEILING_FAN_GRAPHIC_CENTER_INACTIVE_COLOR = to0xHex(
  colors.colorCeilingFanCenterInActive
);
export const CEILING_FAN_GRAPHIC_CENTER_INACTIVE_BORDER_COLOR = to0xHex(
  colors.colorCeilingFanCenterBorderInActive
);
export const OUTLET_RECT_ACTIVE_COLOR = to0xHex(colors.colorOutletRectActive);
export const OUTLET_RECT_INACTIVE_COLOR = to0xHex(
  colors.colorOutletRectInActive
);
export const OUTLET_INNER_RECT_ACTIVE_COLOR = to0xHex(
  colors.colorOutletInnerRectActive
);
export const OUTLET_INNER_RECT_INACTIVE_COLOR = to0xHex(
  colors.colorOutletInnerRectInActive
);
export const CONTROL_ACTIVE_COLOR = to0xHex(colors.colorControlActive);
export const CONTROL_INACTIVE_COLOR = to0xHex(colors.colorControlInActive);
export const CONTROL_TEXT_ACTIVE_COLOR = to0xHex(colors.colorControlTextActive);
export const CONTROL_TEXT_INACTIVE_COLOR = to0xHex(
  colors.colorControlTextInActive
);
