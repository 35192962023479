import { to0xHex } from "common/utils/colors";
import colors from "styles/_jscolors.module.scss";
import { colorPalette } from "styles/themes/homebase_theme";

// Note(Andrei): We don't want to update all colors for each pixi object now.
// So we will use theme based colors for Measurement bars only.
export const getMeasurementLineColor = () => {
  return to0xHex(colorPalette.mainBlue);
};
export const getMeasurementDisabledLineColor = () => {
  return to0xHex(colorPalette.mainBlue);
};
export const MEASUREMENT_LINE_EDGE_HEIGHT = 6;
export const MEASUREMENT_LINE_WIDTH = 2;
export const MEASUREMENT_LINE_OFFSET = 20;
export const MEASUREMENT_LINE_DISABLED_ALPHA = 0.4;
export const MEASUREMENT_LINE_ALPHA = 1;
export const MEASUREMENT_TEXT_CONTAINER_Z_INDEX = 1;
export const getMeasurementActiveTextBoxColor = () => {
  return to0xHex(colorPalette.mainBlue);
};
export const MEASUREMENT_INACTIVE_TEXT_BOX_COLOR = to0xHex(colors.colorGrey200);
export const MEASUREMENT_TEXT_BOX_TEXT_COLOR = to0xHex(colors.mainBlack);
export const MEASUREMENT_TEXT_BOX_TEXT_ACTIVE_COLOR = to0xHex(
  colors.colorWhite
);
export const MEASUREMENT_TEXT_PADDING = 8;
export const MEASUREMENT_TEXT_BOX_PADDING = 1;
