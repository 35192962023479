import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { OutletTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.OUTLET,
  OutletTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [OutletTypes.SINGLE]: t("Single Box"),
  [OutletTypes.DOUBLE]: t("Double Box"),
  [OutletTypes.TWO_FORTY_V]: t("220/240V"),
  [OutletTypes.WEATHER_RESISTANT]: t("Weather Resistant"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [OutletTypes.SINGLE]: t("Single Box"),
  [OutletTypes.DOUBLE]: t("Double Box"),
  [OutletTypes.TWO_FORTY_V]: t("220/240V"),
  [OutletTypes.WEATHER_RESISTANT]: t("Weather Resistant"),
});

const typeDescriptions: Config["typeDescriptions"] = (t) => ({
  [OutletTypes.SINGLE]: t(
    "A single box outlet is designed to hold one duplex outlet, enabling two devices to be plugged in. It is compact and suitable for standard electrical needs."
  ),
  [OutletTypes.DOUBLE]: t(
    "A double box outlet is designed to hold two duplex outlets, accommodating up to four devices. It provides increased power capacity in a single location."
  ),
  [OutletTypes.TWO_FORTY_V]: t(
    "A 220/240V outlet is designed for high-powered appliances, providing a robust connection for energy-intensive equipment. These outlets are essential for certain household or industrial tasks."
  ),
  [OutletTypes.WEATHER_RESISTANT]: t(
    "A weather-resistant outlet is designed to withstand outdoor conditions, protecting against moisture, rain, and extreme temperatures."
  ),
});

export const OutletsConfigTexts: Config = {
  categoryName: (t) => t("Outlets"),
  itemName: (t) => t("Outlet"),
  categoryDescription: (t) =>
    t(
      "Electrical Outlets, technically called receptacles, are fundamental components of any living or working space. These fixtures provide a safe and convenient means to access electrical power for a multitude of devices and appliances.  Strategically placed throughout a space, outlets ensure seamless integration of technology into daily life, powering everything from lights to laptops, and are an essential consideration in interior design and functionality."
    ),
  itemNames,
  typeNames,
  typeDescriptions,
};
