import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { WallMaterialTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.WALL_MATERIAL,
  WallMaterialTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [WallMaterialTypes.DRYWALL]: t("Drywall"),
  [WallMaterialTypes.LATH_AND_PLASTER]: t("Lath & Plaster"),
  [WallMaterialTypes.WOOD]: t("Wood"),
  [WallMaterialTypes.TILE]: t("Tile"),
  [WallMaterialTypes.BRICK]: t("Brick"),
  [WallMaterialTypes.STONE]: t("Stone"),
});

const typeNames: Config["itemNames"] = (t) => ({
  [WallMaterialTypes.DRYWALL]: t("Drywalls"),
  [WallMaterialTypes.LATH_AND_PLASTER]: t("Lath & Plasters"),
  [WallMaterialTypes.WOOD]: t("Woods"),
  [WallMaterialTypes.TILE]: t("Tiles"),
  [WallMaterialTypes.BRICK]: t("Bricks"),
  [WallMaterialTypes.STONE]: t("Stones"),
});

export const WallMaterialsConfigTexts: Config = {
  categoryName: (t) => t("Walls"),
  categoryDescription: (t) => t("Wall Materials Description"),
  itemName: (t) => t("Wall Material"),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
