import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { ElectricalPanelTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.ELECTRICAL_PANEL,
  ElectricalPanelTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [ElectricalPanelTypes.MAIN_PANEL]: t("Main Panel"),
  [ElectricalPanelTypes.SUB_PANEL]: t("Sub Panel"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [ElectricalPanelTypes.MAIN_PANEL]: t("Main Panel"),
  [ElectricalPanelTypes.SUB_PANEL]: t("Sub Panel"),
});

export const ElectricalPanelConfigTexts: Config = {
  categoryName: (t) => t("Electrical Panel"),
  itemName: (t) => t("Electrical Panel"),
  categoryDescription: (t) =>
    t(
      "An electrical panel, also known as a breaker box, is the command center of a building's electrical system. It distributes power to different circuits through circuit breakers, safeguarding against overloads. By regulating electricity flow, it ensures safe and efficient operation of appliances and devices throughout the space. The size of the panel corresponds to the number of circuits it can accommodate. Larger panels have more space for circuit breakers, allowing for a greater number of individual circuits to be connected."
    ),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
