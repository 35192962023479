import don_bailey_flooring_logo_fallback from "assets/integration/don_bailey_flooring.png";
import don_bailey_flooring_logo from "assets/integration/don_bailey_flooring.webp";
import { ReactComponent as FamilyOwnedAndOperated } from "assets/integration/family_owned_and_operated.svg";
import { ReactComponent as GreatFloorsForLLess } from "assets/integration/great_floors_for_lless.svg";
import home_depot_logo_fallback from "assets/integration/home_depot.png";
import home_depot_logo from "assets/integration/home_depot.webp";
import { ReactComponent as LetsDoIt } from "assets/integration/lets_do_it.svg";
import lumber_liquidators_logo_fallback from "assets/integration/lumber_liquidators.png";
import lumber_liquidators_logo from "assets/integration/lumber_liquidators.webp";
import { InteriorFloorMaterialTypes } from "constants/guides/FloorMaterial/InteriorFloorMaterial/enums";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideType } from "constants/guides/GuideType";
import { getEphemeral } from "core/persistence";
import { OrchestratorMachineContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { GuideIntentType } from "pages/Guides/enums";
import { FC } from "react";
import { ImageItem } from "shared/types/ImageItem";
import { SvgIconComponent } from "shared/types/SvgIcon";

import { PartnerLogoAndCaptionLoader } from "./components/PartnerLogoAndCaptionLoader/PartnerLogoAndCaptionLoader";

const EMBEDDED_GUIDE_PARTNER_GUIDE_STORAGE_KEY = "embedded-guide-partner";

enum EMBEDDED_GUIDE_PARTNER {
  "The Home Depot" = "The Home Depot",
  "Lumber Liquidators" = "Lumber Liquidators",
  "Don Bailey Flooring" = "Don Bailey Flooring",
}

const getEmbeddedGuidePartnerName = () => {
  const urlParams = new URLSearchParams(document.location.search);
  const partner = urlParams.get("partner") as EMBEDDED_GUIDE_PARTNER;
  if (EMBEDDED_GUIDE_PARTNER[partner]) {
    getEphemeral().setStringSync(
      EMBEDDED_GUIDE_PARTNER_GUIDE_STORAGE_KEY,
      partner
    );
    return partner as EMBEDDED_GUIDE_PARTNER;
  }
  const sessionPartner = getEphemeral().getStringSync(
    EMBEDDED_GUIDE_PARTNER_GUIDE_STORAGE_KEY
  ) as EMBEDDED_GUIDE_PARTNER;
  if (EMBEDDED_GUIDE_PARTNER[sessionPartner]) {
    return sessionPartner;
  }
  return null;
};

export const EMBEDDED_GUIDE_PARTNER_NAME = getEmbeddedGuidePartnerName();

export const DEMO_RESULT_GUIDE_TYPE = EMBEDDED_GUIDE_PARTNER_NAME
  ? GuideType.SHOP_INTEGRATION
  : GuideType.TRY_IT_OUT_GUIDE;

const EMBEDDED_GUIDE_PARTNER_LOGOS: Record<EMBEDDED_GUIDE_PARTNER, ImageItem> =
  {
    [EMBEDDED_GUIDE_PARTNER["The Home Depot"]]: {
      src: home_depot_logo,
      fallbackSrc: home_depot_logo_fallback,
    },
    [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: {
      src: lumber_liquidators_logo,
      fallbackSrc: lumber_liquidators_logo_fallback,
    },
    [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: {
      src: don_bailey_flooring_logo,
      fallbackSrc: don_bailey_flooring_logo_fallback,
    },
  };

const EMBEDED_GUIDE_PARTNER_DEMO_CONTEXT: Partial<
  Record<EMBEDDED_GUIDE_PARTNER, () => OrchestratorMachineContext>
> = {};

const EMBEDDED_GUIDE_PARTNER_CAPTIONS: Record<
  EMBEDDED_GUIDE_PARTNER,
  SvgIconComponent
> = {
  [EMBEDDED_GUIDE_PARTNER["The Home Depot"]]: LetsDoIt,
  [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: GreatFloorsForLLess,
  [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: FamilyOwnedAndOperated,
};

const EMEBEDED_GUIDE_PARTNER_LOADERS: Record<
  EMBEDDED_GUIDE_PARTNER,
  FC<{ onProceed: () => void }>
> = {
  [EMBEDDED_GUIDE_PARTNER["The Home Depot"]]: PartnerLogoAndCaptionLoader,
  [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: PartnerLogoAndCaptionLoader,
  [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: PartnerLogoAndCaptionLoader,
};

const EMBEDDED_GUIDE_PARTNER_THEMES: Record<
  EMBEDDED_GUIDE_PARTNER,
  null | string
> = {
  [EMBEDDED_GUIDE_PARTNER["The Home Depot"]]: "home_depot_theme",
  [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: null,
  [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: null,
};

type ItemToIntent = Partial<Record<GuideItemTypeCategory, GuideIntentType[]>>;
type ItemToBoolean = Partial<Record<GuideItemTypeCategory, boolean>>;

const EMBEDDED_GUIDE_PARTNER_PREDEFINED_GUIDES: Partial<
  Record<EMBEDDED_GUIDE_PARTNER, Partial<Record<GuideType, ItemToIntent>>>
> = {
  [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: [GuideIntentType.ADD],
    },
  },
  [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: [GuideIntentType.ADD],
    },
  },
};

const EMBEDDED_GUIDE_PARTNER_SHOULD_SKIP_CS_GUIDES: Partial<
  Record<EMBEDDED_GUIDE_PARTNER, Partial<Record<GuideType, ItemToBoolean>>>
> = {
  [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: true,
    },
  },
  [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: true,
    },
  },
};

export const EMBEDDED_GUIDE_PARTNER_FLOOR_MATERIAL_ITEMS_TYPES:
  | InteriorFloorMaterialTypes[]
  | null =
  (
    {
      [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: [
        InteriorFloorMaterialTypes.WOOD,
      ],
      [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: [
        InteriorFloorMaterialTypes.WOOD,
        InteriorFloorMaterialTypes.TILE,
        InteriorFloorMaterialTypes.CARPET,
      ],
    } as Partial<Record<EMBEDDED_GUIDE_PARTNER, InteriorFloorMaterialTypes[]>>
  )[EMBEDDED_GUIDE_PARTNER_NAME] ?? null;

export const EMBEDDED_GUIDE_PARTNER_THEME =
  EMBEDDED_GUIDE_PARTNER_THEMES[EMBEDDED_GUIDE_PARTNER_NAME];

export const EMBEDDED_GUIDE_PARTNER_LOADER =
  EMEBEDED_GUIDE_PARTNER_LOADERS[EMBEDDED_GUIDE_PARTNER_NAME];

export const EMBEDDED_GUIDE_PARTNER_CAPTION =
  EMBEDDED_GUIDE_PARTNER_CAPTIONS[EMBEDDED_GUIDE_PARTNER_NAME];

export const EMBEDDED_GUIDE_PARTNER_LOGO =
  EMBEDDED_GUIDE_PARTNER_LOGOS[EMBEDDED_GUIDE_PARTNER_NAME];

export const EMBEDDED_GUIDE_PARTNER_DEMO_CONTEXT =
  EMBEDED_GUIDE_PARTNER_DEMO_CONTEXT[EMBEDDED_GUIDE_PARTNER_NAME];

export const EMBEDDED_GUIDE_PARTNER_PREDEFINED_GUIDE =
  EMBEDDED_GUIDE_PARTNER_PREDEFINED_GUIDES[EMBEDDED_GUIDE_PARTNER_NAME];

export const EMBEDDED_GUIDE_PARTNER_SHOULD_SKIP_CS_GUIDE =
  EMBEDDED_GUIDE_PARTNER_SHOULD_SKIP_CS_GUIDES[EMBEDDED_GUIDE_PARTNER_NAME];
