import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { LightingFixtureTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.LIGHTING_FIXTURE,
  LightingFixtureTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [LightingFixtureTypes.FLUSHMOUNT]: t("Flush Mount"),
  [LightingFixtureTypes.HANGING_LIGHT]: t("Pendant"),
  [LightingFixtureTypes.RECESSED_LIGHT]: t("Recessed"),
  [LightingFixtureTypes.WALL_SCONCES]: t("Wall Sconce"),
  [LightingFixtureTypes.MONORAIL]: t("Track"),
  [LightingFixtureTypes.LED]: t("LED"),
  [LightingFixtureTypes.SEMI_FLUSHMOUNT]: t("Semi Flush Mount"),
  [LightingFixtureTypes.CHANDELIER]: t("Chandelier"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [LightingFixtureTypes.FLUSHMOUNT]: t("Flush Mount"),
  [LightingFixtureTypes.HANGING_LIGHT]: t("Pendant"),
  [LightingFixtureTypes.RECESSED_LIGHT]: t("Recessed"),
  [LightingFixtureTypes.WALL_SCONCES]: t("Wall Sconce"),
  [LightingFixtureTypes.MONORAIL]: t("Track"),
  [LightingFixtureTypes.LED]: t("LED"),
  [LightingFixtureTypes.SEMI_FLUSHMOUNT]: t("Semi Flush Mount"),
  [LightingFixtureTypes.CHANDELIER]: t("Chandelier"),
});

const typeDescriptions: Config["typeDescriptions"] = (t) => ({
  [LightingFixtureTypes.FLUSHMOUNT]: t(
    "A flush mount light is installed directly against the ceiling, offering a low-profile design that’s ideal for spaces with lower ceilings. It provides even, widespread lighting."
  ),
  [LightingFixtureTypes.HANGING_LIGHT]: t(
    "A pendant light is a single fixture that hangs from the ceiling, often suspended by a cord, chain, or rod. It provides focused or decorative lighting, ideal for various settings."
  ),
  [LightingFixtureTypes.RECESSED_LIGHT]: t(
    "Recessed lighting, also known as can lighting, is installed flush with the ceiling for a clean, unobtrusive look. It provides focused or ambient lighting, making it versatile for any room."
  ),
  [LightingFixtureTypes.WALL_SCONCES]: t(
    "A wall sconce is a decorative light fixture mounted on walls, offering both functional and ambient lighting. It comes in various styles, from traditional to modern, to complement any décor."
  ),
  [LightingFixtureTypes.MONORAIL]: t(
    "Track lighting features multiple light fixtures mounted on a linear or curved track, allowing for adjustable, directional lighting. It’s versatile and ideal for highlighting specific areas or features."
  ),
  [LightingFixtureTypes.LED]: t(
    "LED lighting strips are flexible, adhesive-backed lights that can be cut to fit and installed in various locations for ambient or accent lighting. They offer energy efficiency and customizable colors or brightness."
  ),
  [LightingFixtureTypes.SEMI_FLUSHMOUNT]: t(
    "A semi-flush mount light hangs slightly below the ceiling, creating a small gap for a more decorative and dimensional look. It combines the benefits of flush and pendant lighting."
  ),
  [LightingFixtureTypes.CHANDELIER]: t(
    "A chandelier is a suspended decorative light fixture with multiple branches, often featuring glass, crystal, or metal elements. It serves as a statement piece, adding elegance and ambiance to a space."
  ),
});

export const LightingFixturesConfigTexts: Config = {
  categoryName: (t) => t("Lighting Fixtures"),
  itemName: (t) => t("Lighting Fixture"),
  categoryDescription: (t) =>
    t(
      "Interior lighting design is one of the most important interior design basics, yet it's often the most neglected by DIY decorators. No matter how carefully you've furnished, arranged, and accessorized, you do not have a well-decorated home until you've implemented some lighting design basics."
    ),
  itemNames,
  typeNames,
  typeDescriptions,
};
