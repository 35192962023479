import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { SmokeAlarmTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SMOKE_ALARM,
  SmokeAlarmTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [SmokeAlarmTypes.BATTERY_OPERATED]: t("Battery operated Alarm"),
  [SmokeAlarmTypes.HARDWIRED]: t("Hardwired Alarm"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [SmokeAlarmTypes.BATTERY_OPERATED]: t("Battery Operated"),
  [SmokeAlarmTypes.HARDWIRED]: t("Hardwired"),
});

const typeDescriptions: Config["typeDescriptions"] = (t) => ({
  [SmokeAlarmTypes.HARDWIRED]:
    "A hard-wired smoke alarm is directly connected to a building's electrical system and typically includes a battery backup to ensure functionality during power outages.",
  [SmokeAlarmTypes.BATTERY_OPERATED]:
    "A battery-operated smoke alarm runs entirely on batteries, making it easy to install and relocate without wiring. Regular battery replacement ensures continuous operation.",
});

export const SmokeAlarmConfigTexts: Config = {
  categoryName: (t) => t("Smoke Alarms"),
  itemName: (t) => t("Smoke Alarm"),
  categoryDescription: (t) =>
    t(
      "Smoke alarms are essential components of any living space, serving as vigilant guardians against the threat of fires or carbon monoxide. These compact yet powerful devices possess the remarkable ability to swiftly detect the earliest traces of smoke, sounding a sharp alarm that provides invaluable time for escape and intervention. Beyond their piercing sound, smoke alarms offer peace of mind, ensuring the safety of loved ones and the preservation of property."
    ),
  itemNames,
  typeNames,
  typeDescriptions,
};
