import {
  Line as FlattenLine,
  Point as FlattenPoint,
  Polygon,
  Segment,
  segment,
} from "@flatten-js/core";
import { Graphics as PixiGraphics } from "@pixi/graphics";
import {
  DistanceHelperInterSectionPointsType,
  Line,
  LineDirection,
  Points,
} from "components/HBFloorPlan/HBFloorPlan.types";
import {
  angleToRadians,
  lineAngle as _lineAngle,
  lineLength as _lineLength,
  Point,
} from "geometric";
import intersectsBoxPolygon from "intersects/box-polygon";
import _lineIntersectsLine from "intersects/line-line";
import lineToPolygon from "intersects/lineToPolygon";
import { round } from "lodash-es";
import { Coords } from "pages/Guides/types";
import { AUTO_ALIGN_OFFSET, EPSILON } from "shared/floorPlan/constants";

import {
  distanceBetweenCoords,
  extractCoords,
  getAlignBetweenCoords,
  isEqualCoords,
  mapPointsToList,
  minusCoords,
  sumCoords,
} from "./points.utils";

interface SegmentWithIndex {
  segment: Line;
  index: number;
}

export const mapPointsToConnectedLines = (points: Points): Line[] => {
  return points.reduce<Line[]>((acc, curr, index) => {
    const isFirstElement = index === 0;
    const lastIndex = acc.length - 1;
    const isLastElement = points.length - 1 === index;

    if (isFirstElement) {
      acc.push({
        p1: { x: curr.x, y: curr.y },
        p2: {
          x: undefined,
          y: undefined,
        },
      });
    } else {
      acc[lastIndex].p2 = { x: curr.x, y: curr.y };

      if (!isLastElement) {
        acc.push({
          p1: { x: curr.x, y: curr.y },
          p2: { x: undefined, y: undefined },
        });
      }
    }

    return acc;
  }, []);
};

export const mapPointsToSegments = (points: Points): Line[] => {
  const length = points.length;
  const segments: Line[] = [];
  for (let i = 0; i < points.length; i++) {
    const p1 = { x: points[i].x, y: points[i].y };
    const p2 = { x: points[(i + 1) % length].x, y: points[(i + 1) % length].y };
    segments.push({ p1, p2 } as Line);
  }
  return segments;
};

export const mapConnectedLinesToPoints = (lines: Line[]): Coords[] => {
  const points = lines.map<Coords>((line) => line.p1);

  const lastLineIndex = lines.length - 1;
  const lastPoint = lines[lastLineIndex];
  points.push(lastPoint.p2);

  return points;
};

export const calcIsInsideBox = (props: {
  line: Line;
  point: Coords;
  width?: number;
  height?: number;
  lineThickness?: number;
}) => {
  const { line, point, width = 0, height = 0, lineThickness = 0 } = props;
  const { p1, p2 } = line;
  const polygon = lineToPolygon(p1.x, p1.y, p2.x, p2.y, lineThickness);

  return intersectsBoxPolygon(point.x, point.y, width, height, polygon);
};

export const getSegmentAngleInDeg = (segment: Line): number => {
  const p1: Point = [segment.p1.x, segment.p1.y];
  const p2: Point = [segment.p2.x, segment.p2.y];
  const lineAngle = _lineAngle([p1, p2]);

  return lineAngle;
};

export const getSegmentAngleInRad = (segment: Line): number => {
  const deg = getSegmentAngleInDeg(segment);
  return angleToRadians(deg);
};

export const getIntersectingLine = (
  lines: Line[],
  point: Coords,
  thickness: number
) =>
  lines.find((line) =>
    calcIsInsideBox({ line, point, lineThickness: thickness })
  );

const isLineAngleHorizontal = (angle: number): boolean => {
  return Math.abs(angle) % 180 === 0;
};

const isLineAngleVertical = (angle: number): boolean => {
  return Math.abs(angle) % 90 === 0 && Math.abs(angle) % 180 === 90;
};

export const dashedLineTo = (
  g: PixiGraphics,
  x: number,
  y: number,
  params?: {
    dash?: number;
    gap?: number;
    from?: Coords;
  }
) => {
  const { dash = 8, gap = 4, from } = params || {};

  const lastPosition = g.currentPath.points || [];

  const currentPosition = {
    x: from?.x ?? (lastPosition[lastPosition.length - 2] || 0),
    y: from?.y ?? (lastPosition[lastPosition.length - 1] || 0),
  };

  const toRight = x > currentPosition.x;
  const toBottom = y > currentPosition.y;

  for (
    ;
    (toRight ? currentPosition.x < x : currentPosition.x > x) ||
    (toBottom ? currentPosition.y < y : currentPosition.y > y);

  ) {
    currentPosition.x = toRight
      ? Math.min(currentPosition.x + dash, x)
      : Math.max(currentPosition.x - dash, x);
    currentPosition.y = toBottom
      ? Math.min(currentPosition.y + dash, y)
      : Math.max(currentPosition.y - dash, y);
    g.lineTo(currentPosition.x, currentPosition.y);

    currentPosition.x = toRight
      ? Math.min(currentPosition.x + gap, x)
      : Math.max(currentPosition.x - gap, x);
    currentPosition.y = toBottom
      ? Math.min(currentPosition.y + gap, y)
      : Math.max(currentPosition.y - gap, y);
    g.moveTo(currentPosition.x, currentPosition.y);
  }

  return g;
};

export const getLineDirection = (line: Line): LineDirection => {
  const lineAngle = getSegmentAngleInDeg(line);

  switch (lineAngle) {
    case 0:
      return "right";
    case 90:
      return "down";
    case 180:
      return "left";
    case -90:
      return "up";
    default:
      throw new Error(`Unsupported line angle: ${lineAngle}`);
  }
};

export const getSegmentMidPoint = ({ p1, p2 }: Line): Coords => {
  const seg = segment(p1.x, p1.y, p2.x, p2.y);
  const mid = seg.middle();
  return { x: mid.x, y: mid.y };
};

export const getSegmentLength = (line: Line): number => {
  const p1: Point = [line.p1.x, line.p1.y];
  const p2: Point = [line.p2.x, line.p2.y];

  return round(_lineLength([p1, p2]));
};

export const getSegmentLengthWithoutRounding = (line: Line): number => {
  const p1: Point = [line.p1.x, line.p1.y];
  const p2: Point = [line.p2.x, line.p2.y];

  return _lineLength([p1, p2]);
};

export const createLine = (
  p1x: number,
  p1y: number,
  p2x: number,
  p2y: number
): Line => {
  return {
    p1: {
      x: p1x,
      y: p1y,
    },
    p2: {
      x: p2x,
      y: p2y,
    },
  };
};

/** @description Original line (PQ) length will be decreased and (RS) line is returned
 *     ────┼─────────────────┼────
 *    P    R                 S    Q
 * @param  line - Line to be decreased in length
 * @param  decreaseBy - Value to decrease the length of the line by - cuts off 'decreaseBy' / 2 from both sides of the line
 */
export const decreaseLineLengthBy = (line: Line, decreaseBy: number): Line => {
  const { p1, p2 } = line;

  const r2 = decreaseBy / 2;
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  const mag = Math.hypot(dx, dy);

  if (dx === 0 && dy === 0) {
    return line;
  }

  const x1 = p1.x + (r2 * dx) / mag;
  const y1 = p1.y + (r2 * dy) / mag;
  const x2 = p2.x - (r2 * dx) / mag;
  const y2 = p2.y - (r2 * dy) / mag;

  return {
    p1: {
      x: round(x1, 2),
      y: round(y1, 2),
    },
    p2: {
      x: round(x2, 2),
      y: round(y2, 2),
    },
  };
};

export const increaseLineLengthBy = (line: Line, increaseByBy: number) => {
  return decreaseLineLengthBy(line, -increaseByBy);
};

export const isLineHorizontal = (line: Line): boolean => {
  const lineAngle = getSegmentAngleInDeg(line);
  return isLineAngleHorizontal(lineAngle);
};

export const isLineVertical = (line: Line): boolean => {
  const lineAngle = getSegmentAngleInDeg(line);
  return isLineAngleVertical(lineAngle);
};

export const getHorizontalLines = (lines: Line[]): Line[] =>
  lines.filter(isLineHorizontal);

export const getVerticalLines = (lines: Line[]): Line[] =>
  lines.filter(isLineVertical);

export const lineIntersectsLine = (props: {
  line1: Line;
  line2: Line;
  line1Thickness?: number;
  line2Thickness?: number;
}): boolean => {
  const { line1, line2, line1Thickness = 0, line2Thickness = 0 } = props;

  return _lineIntersectsLine(
    line1.p1.x,
    line1.p1.y,
    line1.p2.x,
    line1.p2.y,
    line2.p1.x,
    line2.p1.y,
    line2.p2.x,
    line2.p2.y,
    line1Thickness,
    line2Thickness
  );
};

export const getFirstAndLastLineFromPoints = (
  points: Coords[]
): [Line, Line] => {
  const lastIndex = points.length - 1;
  const firstLine = {
    p1: points[0],
    p2: points[1],
  };

  const lastLine = {
    p1: points[lastIndex - 1],
    p2: points[lastIndex],
  };

  return [firstLine, lastLine];
};

export const isLineAngleMoreHorizontal = (line: Line): boolean => {
  const angle = getSegmentAngleInDeg(line);
  return angle >= 0 && angle <= 90;
};

export const isSegmentMoreHorizontal = (segment: Line): boolean => {
  const angle = getSegmentAngleInRad(segment);
  return Math.abs(Math.cos(angle)) < Math.abs(Math.sin(angle));
};

export const isSegmentContainsPoint = (
  segment: Line,
  point: Coords
): boolean => {
  const flattenPoint = new FlattenPoint(point.x, point.y);
  const flattenSegment = new Segment(
    new FlattenPoint(segment.p1.x, segment.p1.y),
    new FlattenPoint(segment.p2.x, segment.p2.y)
  );

  return flattenSegment.contains(flattenPoint);
};

export const getNearestPointOntoLine = (
  segment: Line,
  point: Coords
): Coords => {
  const flattenPoint = new FlattenPoint(point.x, point.y);
  const flattenLine = new FlattenLine(
    new FlattenPoint(segment.p1.x, segment.p1.y),
    new FlattenPoint(segment.p2.x, segment.p2.y)
  );

  if (flattenLine.contains(flattenPoint)) {
    return point;
  }

  const [, shortestSegment] = flattenPoint.distanceTo(flattenLine);
  const stickedPoint = { x: shortestSegment.pe.x, y: shortestSegment.pe.y };

  return stickedPoint;
};

export const rotateSegment = (
  segment: [Coords, Coords],
  rotationRad: number,
  from: Coords
): [Coords, Coords] => {
  const [_p1, _p2] = segment;

  const _start = minusCoords(_p1, from);
  const _end = minusCoords(_p2, from);

  const cosAngle = Math.cos(rotationRad);
  const sinAngle = Math.sin(rotationRad);

  const start = {
    x: _start.x * cosAngle - _start.y * sinAngle,
    y: _start.x * sinAngle + _start.y * cosAngle,
  };
  const end = {
    x: _end.x * cosAngle - _end.y * sinAngle,
    y: _end.x * sinAngle + _end.y * cosAngle,
  };

  let p1 = sumCoords(start, from);
  let p2 = sumCoords(end, from);

  if (p1.x > p2.x || (p1.x === p2.x && p1.y > p2.y)) {
    const temp = p1;
    p1 = p2;
    p2 = temp;
  }

  return [p1, p2];
};

export const splitSegment = (
  startPoint: Coords,
  endPoint: Coords,
  splitLength: number
): Coords[] => {
  const lengthX = endPoint.x - startPoint.x;
  const lengthY = endPoint.y - startPoint.y;
  const length = Math.sqrt(lengthX ** 2 + lengthY ** 2);
  const splitCount = Math.floor(length / splitLength);

  const splitPoints: Coords[] = [];
  for (let i = 1; i <= splitCount; i++) {
    const t = i / (splitCount + 1);
    const x = startPoint.x + t * lengthX;
    const y = startPoint.y + t * lengthY;
    splitPoints.push({ x, y });
  }

  return splitPoints;
};

export const calculateNewEndpointsFromSegmentAndLength = (
  segment: Line,
  length: number
): Line => {
  const midPoint = getSegmentMidPoint(segment);
  const segmentAngleRad = getSegmentAngleInRad(segment);

  const _p1 = {
    x: midPoint.x + (length / 2) * Math.cos(segmentAngleRad),
    y: midPoint.y + (length / 2) * Math.sin(segmentAngleRad),
  };
  const _p2 = {
    x: midPoint.x - (length / 2) * Math.cos(segmentAngleRad),
    y: midPoint.y - (length / 2) * Math.sin(segmentAngleRad),
  };
  return { p1: _p1, p2: _p2 };
};

export const calculateCastPointOfSegmentFromCoords = (
  line: Line,
  coords: Coords
): Coords => {
  const { p1, p2 } = line;
  const segment = new Segment(
    new FlattenPoint(p1.x, p1.y),
    new FlattenPoint(p2.x, p2.y)
  );
  const point = new FlattenPoint(coords.x, coords.y);
  const [, shortSegment] = segment.distanceTo(point);
  return { x: shortSegment.ps.x, y: shortSegment.ps.y };
};

export const calculateCastPointOfLineFromCoords = (
  line: Line,
  coords: Coords
): Coords => {
  const { p1, p2 } = line;
  const _line = new FlattenLine(
    new FlattenPoint(p1.x, p1.y),
    new FlattenPoint(p2.x, p2.y)
  );
  const point = new FlattenPoint(coords.x, coords.y);
  const [, shortSegment] = _line.distanceTo(point);
  return { x: shortSegment.ps.x, y: shortSegment.ps.y };
};

export const isLineIntersectsWithShapes = (params: {
  line: Line;
  shapes: Points[];
  ignoreWall: { shapeIndex: number; wallIndex: number };
}) => {
  const { line, shapes, ignoreWall } = params;
  const { shapeIndex, wallIndex } = ignoreWall;

  const segment1 = segment(line.p1.x, line.p1.y, line.p2.x, line.p2.y);

  for (let i = 0; i < shapes.length; i++) {
    const shape = shapes[i];
    for (let j = 0; j < shape.length - 1; j++) {
      if (shapeIndex === i && wallIndex === j) {
        continue;
      }

      const segment2 = segment(
        shape[j].x,
        shape[j].y,
        shape[j + 1].x,
        shape[j + 1].y
      );

      if (segment1.ps.x === segment2.ps.x && segment1.ps.y === segment2.ps.y) {
        continue;
      }
      if (segment1.ps.x === segment2.pe.x && segment1.ps.y === segment2.pe.y) {
        continue;
      }
      if (segment1.pe.x === segment2.ps.x && segment1.pe.y === segment2.ps.y) {
        continue;
      }
      if (segment1.pe.x === segment2.pe.x && segment1.pe.y === segment2.pe.y) {
        continue;
      }

      const ip = segment1.intersect(segment2);
      if (ip.length > 0) {
        return true;
      }
    }
  }

  return false;
};

const rotateVector = (vector: Coords, rotationRad: number): Coords => {
  const cosTheta = Math.cos(rotationRad);
  const sinTheta = Math.sin(rotationRad);

  const rotatedX = vector.x * cosTheta - vector.y * sinTheta;
  const rotatedY = vector.x * sinTheta + vector.y * cosTheta;

  return {
    x: rotatedX,
    y: rotatedY,
  };
};

const calculateNormalizedVectorFromSegment = (segment: Line): Coords => {
  const lineLength = getSegmentLength(segment);
  const directionVector = {
    x: segment.p2.x - segment.p1.x,
    y: segment.p2.y - segment.p1.y,
  };
  const unitVector = {
    x: directionVector.x / lineLength,
    y: directionVector.y / lineLength,
  };
  return unitVector;
};

/**
 * @description This function calculates a new point at a specified distance perpendicular to the segment, considering the segment's angle
 */
export const calculatePerpendicularPointAlongSegment = (
  segment: Line,
  fromPoint: Coords,
  distance: number = 0
): Coords => {
  const unitVector = calculateNormalizedVectorFromSegment(segment);
  const rotatedUnitVector = rotateVector(
    unitVector,
    getSegmentAngleInRad(segment) + Math.PI / 2
  );
  const scaledVector = {
    x: rotatedUnitVector.x * distance,
    y: rotatedUnitVector.y * distance,
  };
  const moveVector = rotateVector(
    scaledVector,
    Math.abs(getSegmentAngleInRad(segment) % Math.PI)
  );
  const newPoint = {
    x: fromPoint.x + moveVector.x,
    y: fromPoint.y + moveVector.y,
  };
  return newPoint;
};

/**
 * @description This function calculates a new point at a specified distance perpendicular to the segment
 */
export const calculatePerpendicularPointFromSegment = (
  segment: Line,
  fromPoint: Coords,
  distance: number
): Coords => {
  const unitVector = calculateNormalizedVectorFromSegment(segment);
  const rotatedUnitVector = rotateVector(unitVector, -Math.PI / 2);
  const scaledVector = {
    x: rotatedUnitVector.x * distance,
    y: rotatedUnitVector.y * distance,
  };
  const newPoint = {
    x: fromPoint.x + scaledVector.x,
    y: fromPoint.y + scaledVector.y,
  };
  return newPoint;
};

const interpolateColor = (color1: number, color2: number, t: number) => {
  const r1 = (color1 >> 16) & 0xff;
  const g1 = (color1 >> 8) & 0xff;
  const b1 = color1 & 0xff;
  const r2 = (color2 >> 16) & 0xff;
  const g2 = (color2 >> 8) & 0xff;
  const b2 = color2 & 0xff;
  const r = Math.round(r1 + t * (r2 - r1));
  const g = Math.round(g1 + t * (g2 - g1));
  const b = Math.round(b1 + t * (b2 - b1));
  return (r << 16) | (g << 8) | b;
};

export const drawGradientLine = (params: {
  g: PixiGraphics;
  p1: Coords;
  p2: Coords;
  color1: number;
  color2: number;
  gradientSteps?: number;
  lineThickness?: number;
}) => {
  const {
    g,
    p1,
    p2,
    color1,
    color2,
    gradientSteps = 10,
    lineThickness = 1,
  } = params;
  for (let i = 0; i < gradientSteps; i++) {
    const t = i / gradientSteps;
    const x = p1.x + t * (p2.x - p1.x);
    const y = p1.y + t * (p2.y - p1.y);
    const color = interpolateColor(color1, color2, t);
    g.lineStyle(lineThickness, color);
    g.moveTo(x, y);
    const nextT = (i + 1) / gradientSteps;
    const nextX = p1.x + nextT * (p2.x - p1.x);
    const nextY = p1.y + nextT * (p2.y - p1.y);
    g.lineTo(nextX, nextY);
  }
};

export const isNearIntersection = (params: {
  intersectionPoints: Coords[];
  point: Coords;
  deltaLength: number;
}) => {
  const { intersectionPoints, point, deltaLength } = params;
  return intersectionPoints.some((ip) => {
    const dx = point.x - ip.x;
    const dy = point.y - ip.y;
    return Math.sqrt(dx * dx + dy * dy) < deltaLength;
  });
};

export const getAlignBetweenSegmentMidAndCoords = (params: {
  line: Line;
  coords: Coords;
  offset?: number;
}): { line: Line; offset: Coords } | undefined => {
  const { line, coords, offset = AUTO_ALIGN_OFFSET } = params;
  const midPoint = getSegmentMidPoint(line);
  return getAlignBetweenCoords({ coords1: midPoint, coords2: coords, offset });
};

export const distanceFromCoordsToSegment = (
  coords: Coords,
  seg: Line
): { distance: number; segment: Segment } => {
  const { p1, p2 } = seg;
  const point = new FlattenPoint(coords.x, coords.y);
  const segment = new Segment(
    new FlattenPoint(p1.x, p1.y),
    new FlattenPoint(p2.x, p2.y)
  );
  const [distance, _segment] = point.distanceTo(segment);
  return { distance, segment: _segment };
};

export const getSegmentExtensionAlign = (params: {
  line: Line;
  coords: Coords;
  offset?: number;
}): { line: Line; offset: Coords } | undefined => {
  const { line, coords, offset = AUTO_ALIGN_OFFSET } = params;
  const { distance, segment } = distanceFromCoordsToSegment(coords, line);
  if (distance <= offset) {
    const { pe } = segment;
    const updatedCoords = { x: pe.x, y: pe.y };
    const _offset = minusCoords(coords, updatedCoords);
    return { line: { p1: line.p1, p2: updatedCoords }, offset: _offset };
  } else {
    return undefined;
  }
};

export const getPointAtLength = (
  segment: Segment | [Coords, Coords],
  length: number
): Coords => {
  const S =
    segment instanceof Segment
      ? segment
      : new Segment(
          new FlattenPoint(segment[0].x, segment[0].y),
          new FlattenPoint(segment[1].x, segment[1].y)
        );

  if (S.length === 0) {
    return extractCoords(S.ps);
  }

  const coef = length / S.length;

  const x = (S.pe.x - S.ps.x) * coef + S.ps.x;
  const y = (S.pe.y - S.ps.y) * coef + S.ps.y;

  return {
    x,
    y,
  };
};

export const isSegmentsParallel = (seg1: Line, seg2: Line): boolean => {
  const line1 = new FlattenLine(
    new FlattenPoint(seg1.p1.x, seg1.p1.y),
    new FlattenPoint(seg1.p2.x, seg1.p2.y)
  );
  const line2 = new FlattenLine(
    new FlattenPoint(seg2.p1.x, seg2.p1.y),
    new FlattenPoint(seg2.p2.x, seg2.p2.y)
  );
  return line1.parallelTo(line2);
};

export const translateSegment = (
  segment: Segment,
  distanceX: number,
  distanceY: number
): Segment => {
  const { ps, pe } = segment;
  const tPs = sumCoords(ps, { x: distanceX, y: distanceY });
  const tPe = sumCoords(pe, { x: distanceX, y: distanceY });
  const translatedSegment = new Segment(
    new FlattenPoint(tPs.x, tPs.y),
    new FlattenPoint(tPe.x, tPe.y)
  );
  return translatedSegment;
};

export const getOffsetPointFromStart = (
  start: Coords,
  end: Coords,
  distance: number
): Coords => {
  const dx = end.x - start.x;
  const dy = end.y - start.y;

  const length = Math.sqrt(dx * dx + dy * dy);

  if (length === 0) {
    return { x: start.x, y: start.y };
  }

  const unitX = dx / length;
  const unitY = dy / length;

  const pointAtDistance = {
    x: start.x + unitX * distance,
    y: start.y + unitY * distance,
  };

  return pointAtDistance;
};

export const calcSegmentIntersectionWithPolygon = (
  segment: Line,
  polygon: Coords[]
): Coords | undefined => {
  const _segment = new Segment(
    new FlattenPoint(segment.p1.x, segment.p1.y),
    new FlattenPoint(segment.p2.x, segment.p2.y)
  );
  const _polygon = new Polygon(mapPointsToList(polygon));
  const intersections = _segment.intersect(_polygon);
  const result =
    intersections.length > 0
      ? ({ x: intersections[0].x, y: intersections[0].y } as Coords)
      : undefined;
  return result;
};

export const intersectionsBetweenLine = (
  segment1: Line,
  segment2: Line
): Coords[] => {
  const _segment1 = new FlattenLine(
    new FlattenPoint(segment1.p1.x, segment1.p1.y),
    new FlattenPoint(segment1.p2.x, segment1.p2.y)
  );
  const _segment2 = new FlattenLine(
    new FlattenPoint(segment2.p1.x, segment2.p1.y),
    new FlattenPoint(segment2.p2.x, segment2.p2.y)
  );
  const _intersections = _segment1.intersect(_segment2);
  const intersections = _intersections.map(
    (intersection) => ({ x: intersection.x, y: intersection.y } as Coords)
  );
  return intersections;
};

export const findNearWallsPositionsFromItem = (
  wallInfo: Points[],
  itemCoords: Coords,
  initValue: DistanceHelperInterSectionPointsType
): DistanceHelperInterSectionPointsType => {
  let north: Coords = initValue.north;
  let south: Coords = initValue.south;
  let east: Coords = initValue.east;
  let west: Coords = initValue.west;

  for (let i = 0; i < wallInfo.length; i++) {
    if (wallInfo[i].length === 0) {
      continue;
    }
    for (let j = 0; j < wallInfo[i].length - 1; j++) {
      const p1 = wallInfo[i][j] as Coords;
      const p2 = wallInfo[i][j + 1] as Coords;

      if (isEqualCoords(p1, p2)) {
        continue;
      }
      const segment = { p1: p1, p2: p2 } as Line;

      const intersection = calculateCastPointOfLineFromCoords(
        segment,
        itemCoords
      );
      if (!isSegmentContainsPoint(segment, intersection)) {
        continue;
      }

      const helperSegment = { p1: itemCoords, p2: intersection };
      if (
        Math.abs(getSegmentAngleInRad(helperSegment) - -Math.PI / 2) < EPSILON
      ) {
        const newDistance = distanceBetweenCoords(itemCoords, intersection);
        const oldDistance = distanceBetweenCoords(itemCoords, north);
        if (newDistance < oldDistance) {
          north = intersection;
        }
      }
      if (Math.abs(getSegmentAngleInRad(helperSegment) - 0) < EPSILON) {
        const newDistance = distanceBetweenCoords(itemCoords, intersection);
        const oldDistance = distanceBetweenCoords(itemCoords, east);
        if (newDistance < oldDistance) {
          east = intersection;
        }
      }
      if (
        Math.abs(getSegmentAngleInRad(helperSegment) - Math.PI / 2) < EPSILON
      ) {
        const newDistance = distanceBetweenCoords(itemCoords, intersection);
        const oldDistance = distanceBetweenCoords(itemCoords, south);
        if (newDistance < oldDistance) {
          south = intersection;
        }
      }
      if (Math.abs(getSegmentAngleInRad(helperSegment) - Math.PI) < EPSILON) {
        const newDistance = distanceBetweenCoords(itemCoords, intersection);
        const oldDistance = distanceBetweenCoords(itemCoords, west);
        if (newDistance < oldDistance) {
          west = intersection;
        }
      }
    }
  }

  return { north, east, south, west };
};

const isEqualSegment = (seg1: Line, seg2: Line): boolean => {
  return isEqualCoords(seg1.p1, seg2.p1) && isEqualCoords(seg1.p2, seg2.p2);
};

export const getParallelSegments = (
  fromSegment: Line,
  toSegments: Line[]
): SegmentWithIndex[] => {
  const parallelSegments: SegmentWithIndex[] = [];
  const fromSegmentLength = getSegmentLength(fromSegment);

  toSegments.forEach((toSegment, index) => {
    if (isEqualSegment(fromSegment, toSegment)) {
      return;
    }

    const toSegmentLength = getSegmentLength(toSegment);
    if (fromSegmentLength === toSegmentLength) {
      return;
    }

    if (isSegmentsParallel(fromSegment, toSegment)) {
      parallelSegments.push({ segment: toSegment, index });
    }
  });

  return parallelSegments;
};

export const getSegmentsGroupsWithEqualLength = (
  segments: SegmentWithIndex[],
  targetLength: number
): SegmentWithIndex[][] => {
  const result: SegmentWithIndex[][] = [];
  const walls = [...segments];

  const findCombinations = (
    remainingLines: SegmentWithIndex[],
    currentGroup: SegmentWithIndex[],
    currentSum: number,
    lastIndex?: number
  ) => {
    const diff = targetLength - currentSum;
    if (Math.abs(diff) <= 1) {
      result.push([...currentGroup]);
      return;
    }

    if (currentSum > targetLength) {
      return;
    }

    for (let i = 0; i < remainingLines.length; i++) {
      const line = remainingLines[i];
      const lineLength = getSegmentLength(line.segment);

      // Note (Alan): When we get group of walls that sum of lengths are same as target wall,
      // index difference between every wall should be 2 because if not, repeated walls can be used in anther walls group.
      if (lastIndex !== undefined && Math.abs(line.index - lastIndex) !== 2) {
        continue;
      }

      findCombinations(
        remainingLines.slice(i + 1),
        [...currentGroup, line],
        currentSum + lineLength,
        line.index
      );
    }
  };

  findCombinations(walls, [], 0);

  return result;
};
