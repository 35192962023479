import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { SplitSystemInteriorUnitTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SPLIT_SYSTEM_INTERIOR_UNIT,
  SplitSystemInteriorUnitTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [SplitSystemInteriorUnitTypes.DEFAULT]: t("Interior Unit"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [SplitSystemInteriorUnitTypes.DEFAULT]: t("Interior Unit"),
});

export const SplitSystemConfigTexts: Config = {
  categoryName: (t) => t("Interior Unit"),
  itemName: (t) => t("Interior Unit"),
  categoryDescription: (t) => t("Interior Unit description"),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
