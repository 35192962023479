import { KITCHEN_GUIDES_ENABLED, WINDOWS_AND_DOORS_GUIDE_ENABLED } from "./env";
import { GuideItemTypeCategory } from "./guides/GuideItems.enums";
import { GuideType } from "./guides/GuideType";

export const ENABLED_GUIDES: Partial<Record<GuideType, boolean>> = {
  [GuideType.ELECTRICAL]: true,
  [GuideType.WINDOWS_AND_DOORS]: WINDOWS_AND_DOORS_GUIDE_ENABLED,
  [GuideType.FLOOR_MATERIAL]: true,
  [GuideType.KITCHEN_SPECIFIC]: KITCHEN_GUIDES_ENABLED,
};

export const DISABLED_CATEGORIES: Partial<
  Record<GuideItemTypeCategory, boolean>
> = {};
