import { Typography } from "@mui/material";
import { ReactComponent as NookLogo } from "assets/logos/nook_logo.svg";
import cn from "clsx";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./PoweredByNook.module.scss";

interface Props {
  className?: string;
}

export const PoweredByNook: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.powered, className)}>
      <NookLogo className={styles.nook} />
      <Typography variant="p" component="p" className={styles.title}>
        {t("Powered by Nook")}
      </Typography>
    </div>
  );
};
