import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { smartTimeout } from "common/utils/testUtils";
import { FC, ReactNode, useLayoutEffect, useState } from "react";
import { createHomebaseTheme } from "styles/themes/homebase_theme";

interface Props {
  children: ReactNode;
  delay?: number;
}

export const DefaultThemeProvider: FC<Props> = ({ children, delay = 100 }) => {
  const [theme, setTheme] = useState(null);
  useLayoutEffect(() => {
    const createTheme = () => setTheme(createHomebaseTheme());
    if (delay) {
      smartTimeout(() => {
        createTheme();
      }, delay);
    } else {
      createTheme();
    }
  }, [delay]);

  if (!theme) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
    </ThemeProvider>
  );
};
