import { captureException } from "@sentry/react";
import { AppData } from "api/models/AppData";

import { mapV1ToV2 } from "./migrations/mapV1ToV2";
import { mapV2ToV3 } from "./migrations/mapV2ToV3";
import { mapV3ToV4 } from "./migrations/mapV3ToV4";
import { mapV4ToV5 } from "./migrations/mapV4ToV5";
import { mapV5ToV6 } from "./migrations/mapV5ToV6";
import { mapV6ToV7 } from "./migrations/mapV6ToV7";
import { mapV7ToV8 } from "./migrations/mapV7ToV8";
import { mapV8ToV9 } from "./migrations/mapV8ToV9";
import { mapV9ToV10 } from "./migrations/mapV9ToV10";
import { mapV10ToV11 } from "./migrations/mapV10ToV11";
import { mapV11ToV12 } from "./migrations/mapV11ToV12";
import { mapV12ToV13 } from "./migrations/mapV12ToV13";
import { mapV13ToV14 } from "./migrations/mapV13ToV14";
import { mapV14ToV15 } from "./migrations/mapV14ToV15";
import { mapV15ToV16 } from "./migrations/mapV15ToV16";
import { mapV16ToV17 } from "./migrations/mapV16ToV17";
import { mapV17ToV18 } from "./migrations/mapV17ToV18";
import { mapV18ToV19 } from "./migrations/mapV18ToV19";
import { mapV19ToV20 } from "./migrations/mapV19ToV20";
import { mapV20ToV21 } from "./migrations/mapV20ToV21";

const stateMigrations = [
  mapV1ToV2,
  mapV2ToV3,
  mapV3ToV4,
  mapV4ToV5,
  mapV5ToV6,
  mapV6ToV7,
  mapV7ToV8,
  mapV8ToV9,
  mapV9ToV10,
  mapV10ToV11,
  mapV11ToV12,
  mapV12ToV13,
  mapV13ToV14,
  mapV14ToV15,
  mapV15ToV16,
  mapV16ToV17,
  mapV17ToV18,
  mapV18ToV19,
  mapV19ToV20,
  mapV20ToV21,
];

const propertiesMigrations = [];

export const migrateData = (data): { changed: number; data: AppData } => {
  let changed = 0;

  try {
    if (data.state?.version) {
      for (
        let version = data.state.version - 1;
        version < stateMigrations.length;
        version++
      ) {
        data = stateMigrations[version](data);
        changed++;
      }
    }
    if (Array.isArray(data.properties)) {
      for (let i = 0; i < data.properties.length; i++) {
        for (
          let version = data.properties[i].version - 1;
          version < propertiesMigrations.length;
          version++
        ) {
          data.properties[i] = propertiesMigrations[version](
            data.properties[i]
          );
          changed++;
        }
      }
    }
  } catch (error: unknown) {
    // Note(pavel): With broken migration we can block user account entirely.
    // Let's just capture the error and pause migrations wherever it crashed
    // with intention to fix migration in the next release.
    captureException(error);
  }

  return { changed, data };
};
