import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { WindowTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SHAPES,
  WindowTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [WindowTypes.FIXED]: t("Fixed Window"),
  [WindowTypes.DOUBLE_HUNG]: t("Double Hung Window"),
  [WindowTypes.ARCHED]: t("Arched Window"),
  [WindowTypes.BAY]: t("Bay Window"),
  [WindowTypes.BOW]: t("Bow Window"),
  [WindowTypes.CASEMENT]: t("Casement Window"),
  [WindowTypes.SINGLE_HUNG]: t("Single Hung Window"),
  [WindowTypes.GARDEN]: t("Garden Window"),
  [WindowTypes.GLASS_BLOCK]: t("Glass Block Window"),
  [WindowTypes.HOPPER]: t("Hopper Window"),
  [WindowTypes.SLIDING]: t("Sliding Window"),
});
const typeNames: Config["typeNames"] = (t) => ({
  [WindowTypes.FIXED]: t("Fixed Window"),
  [WindowTypes.DOUBLE_HUNG]: t("Double Hung Window"),
  [WindowTypes.ARCHED]: t("Arched Window"),
  [WindowTypes.BAY]: t("Bay Window"),
  [WindowTypes.BOW]: t("Bow Window"),
  [WindowTypes.CASEMENT]: t("Casement Window"),
  [WindowTypes.SINGLE_HUNG]: t("Single Hung Window"),
  [WindowTypes.GARDEN]: t("Garden Window"),
  [WindowTypes.GLASS_BLOCK]: t("Glass Block Window"),
  [WindowTypes.HOPPER]: t("Hopper Window"),
  [WindowTypes.SLIDING]: t("Sliding Window"),
});

export const WindowsConfigTexts: Config = {
  categoryName: (t) => t("Window Types"),
  itemName: (t) => t("Window Types"),
  categoryDescription: (t) => t("Window Types"),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
