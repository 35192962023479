import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { CeilingFanTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.CEILING_FAN,
  CeilingFanTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [CeilingFanTypes.STANDARD_FAN]: t("Standard Fan"),
  [CeilingFanTypes.FAN_WITH_LIGHT]: t("Fan with Light"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [CeilingFanTypes.STANDARD_FAN]: t("Standard Fan"),
  [CeilingFanTypes.FAN_WITH_LIGHT]: t("Fan with light"),
});

const typeDescriptions: Config["typeDescriptions"] = (t) => ({
  [CeilingFanTypes.FAN_WITH_LIGHT]:
    "Fans with integrated lighting combine air circulation with illumination, making them a dual-purpose solution for spaces with a single ceiling fixture. They come in various styles to suit modern or traditional décor.",
  [CeilingFanTypes.STANDARD_FAN]:
    "Standard ceiling fans are simple yet effective for providing air circulation in various spaces. They are available in multiple sizes and finishes to match different interiors.",
});

export const CeilingFansConfigTexts: Config = {
  categoryName: (t) => t("Ceiling Fans"),
  itemName: (t) => t("Ceiling Fan"),
  categoryDescription: (t) =>
    t(
      "Ceiling fans are a popular and practical addition to many homes and spaces. By circulating air throughout a room, they create a cooling breeze in warm weather and help distribute heated air during the colder months, thus aiding in temperature regulation and reducing the reliance on air conditioning or heating systems. Ceiling fans come in a wide variety of styles, sizes, and finishes, making them versatile decorative elements that can complement a room's aesthetic."
    ),
  itemNames,
  typeNames,
  typeDescriptions,
};
