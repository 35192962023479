import { transform } from "api/utils";
import { InteriorFloorMaterial } from "constants/guides/FloorMaterial/InteriorFloorMaterial/types";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { AbstractGuideItem } from "constants/guides/GuideItems.types";
import { GUIDE_INTENTS_ORDER } from "pages/Guides/consts";
import {
  RoomAndSpace,
  RoomPlan,
} from "pages/RoomsAndSpaces/RoomsAndSpaces.types";
import {
  getFloorPlanAreaSizes,
  getPolygonSQFT,
} from "shared/floorPlan/utils/polygon.utils";

import { AppDataV20 } from "./models/AppDataV20";
import { AppDataV21 } from "./models/AppDataV21";

const addRealSizeToInteriorFlooring = (args: {
  items: AbstractGuideItem[];
  roomPlan: RoomPlan;
  roomId: RoomAndSpace["id"];
}) => {
  const { items, roomPlan, roomId } = args;

  return items.map((it) => {
    if (
      it.category !== GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL ||
      it.roomId !== roomId
    ) {
      return it;
    }

    const flooring = it as InteriorFloorMaterial;
    const realArea = getPolygonSQFT(flooring.shape, { roomPlan });

    return {
      ...it,
      realArea,
    };
  });
};

export function mapV20ToV21(data: AppDataV20): AppDataV21 {
  return transform(data, (result) => {
    result.state.version = 21;

    Object.values(result.properties).forEach((property) => {
      property.data.rooms.forEach((room) => {
        if (!room.roomPlan) {
          return;
        }

        const wallMeasurements = room.roomPlan.wallMeasurements;
        const points = room.roomPlan.originbaseShape;

        if (!points || !wallMeasurements) {
          return;
        }

        const sizes = getFloorPlanAreaSizes({
          wallMeasurements,
          points,
        });

        room.roomPlan.size = sizes.size;
        room.roomPlan.realSize = sizes.realSize;
      });

      property.data.activeGuides.forEach((guide) => {
        const roomId = guide._roomId;
        const roomPlan = property.data.rooms.find(
          (r) => r.id === guide._roomId
        )?.roomPlan;

        if (!roomPlan) {
          return;
        }

        for (const intent of ["cs", ...GUIDE_INTENTS_ORDER]) {
          guide[intent].items = addRealSizeToInteriorFlooring({
            items: guide[intent].items,
            roomId,
            roomPlan,
          });
        }
      });

      property.data.hips.forEach((h) => {
        h.planningAreas.rooms.forEach((hipRoom) => {
          if (!hipRoom.guideResults) {
            return;
          }

          const roomPlan = property.data.rooms.find(
            (r) => r.id === hipRoom.id
          )?.roomPlan;

          if (!roomPlan) {
            return;
          }

          for (const intent of ["cs", ...GUIDE_INTENTS_ORDER]) {
            hipRoom.guideResults[intent].items = addRealSizeToInteriorFlooring({
              items: hipRoom.guideResults[intent].items,
              roomId: hipRoom.id,
              roomPlan,
            });
          }
        });
      });
    });
  });
}
