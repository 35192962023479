import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HBQueryClient } from "api/HBQueryClient";
import { fetchData, saveData } from "api/homebase.api";
import { useResetRouterScroll } from "common/hooks/useResetRouterScroll";
import { useWindowsInnerHeight } from "common/hooks/useWindowsInnerHeight";
import { isTouchDevice } from "common/utils/isTouchDevice";
import { SentryReplayDevtools } from "components/SentryReplayController/SentryReplayControllerLazy";
import { SidebarForms } from "components/SidebarForms/SidebarForms";
import { SystemSnackbars } from "components/SystemSnackbars/SystemSnackbars";
import { IS_STAGING, SENTRY_REPLAY_DISABLED } from "constants/env";
import { GlobalGuards } from "core/GlobalGuards/GlobalGuards";
import { GlobalStateProvider } from "core/state/global/GlobalStateProvider";
import { TelemetryProvider, useNavigationTelemetry } from "core/telemetry";
import { EMBEDDED_GUIDE_PARTNER_THEME } from "integration/TryItOut.embeded";
import { GuideMetaContextProvider } from "pages/Guides/components/GuideMetaContextProvider/GuideMetaContextProvider";
import { FC, PropsWithChildren } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { HelmetProvider } from "react-helmet-async";
import { useDidMount } from "rooks";

import { DefaultThemeProvider } from "./DefaultThemeProvider";
import { useIOSMeta } from "./hooks/useIOSMeta";
interface Props {
  onSaveData?: typeof saveData;
  onFetchData?: typeof fetchData;
}
export const AppProviders: FC<Props & PropsWithChildren> = ({
  children,
  onSaveData,
  onFetchData,
}) => {
  useWindowsInnerHeight();
  useNavigationTelemetry();
  useResetRouterScroll();
  useIOSMeta();

  const dnBackend = isTouchDevice() ? TouchBackend : HTML5Backend;
  useDidMount(() => {
    if (EMBEDDED_GUIDE_PARTNER_THEME) {
      document.body.classList.add(EMBEDDED_GUIDE_PARTNER_THEME);
    }
  });

  return (
    <TelemetryProvider>
      <DefaultThemeProvider>
        <HelmetProvider>
          <QueryClientProvider client={HBQueryClient}>
            {process.env.NODE_ENV !== "production" && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
            {IS_STAGING && !SENTRY_REPLAY_DISABLED && <SentryReplayDevtools />}
            <DndProvider backend={dnBackend}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <GlobalStateProvider
                  onSaveData={onSaveData}
                  onFetchData={onFetchData}
                >
                  <GuideMetaContextProvider>
                    <GlobalGuards>{children}</GlobalGuards>
                  </GuideMetaContextProvider>
                  <SidebarForms />
                  <SystemSnackbars />
                </GlobalStateProvider>
              </LocalizationProvider>
            </DndProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </DefaultThemeProvider>
    </TelemetryProvider>
  );
};
