import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { InteriorFloorMaterialTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL,
  InteriorFloorMaterialTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [InteriorFloorMaterialTypes.TILE]: t("Tile"),
  [InteriorFloorMaterialTypes.BRICK]: t("Brick"),
  [InteriorFloorMaterialTypes.CARPET]: t("Carpet"),
  [InteriorFloorMaterialTypes.WOOD]: t("Wood"),
  [InteriorFloorMaterialTypes.VINYL]: t("Vinyl"),
  [InteriorFloorMaterialTypes.CONCRETE]: t("Concrete"),
  [InteriorFloorMaterialTypes.NO_MATERIAL]: t("No Material"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [InteriorFloorMaterialTypes.TILE]: t("Tile"),
  [InteriorFloorMaterialTypes.BRICK]: t("Brick"),
  [InteriorFloorMaterialTypes.CARPET]: t("Carpet"),
  [InteriorFloorMaterialTypes.WOOD]: t("Wood"),
  [InteriorFloorMaterialTypes.VINYL]: t("Vinyl"),
  [InteriorFloorMaterialTypes.CONCRETE]: t("Concrete"),
  [InteriorFloorMaterialTypes.NO_MATERIAL]: t("No Material"),
});

const typeDescriptions: Config["typeDescriptions"] = (t) => ({
  [InteriorFloorMaterialTypes.CARPET]: t(
    "Carpet is a soft, warm flooring option available in a variety of textures, colors, and patterns. It provides comfort underfoot and reduces noise in a space."
  ),
  [InteriorFloorMaterialTypes.TILE]: t(
    "Tile flooring is versatile, water-resistant, and available in a wide range of materials like ceramic, porcelain, and natural stone. It is a practical choice for wet or high-traffic areas."
  ),
  [InteriorFloorMaterialTypes.BRICK]: t(
    "Brick flooring adds rustic charm and durability, often used in traditional or industrial designs. It is easy to maintain and works well in high-traffic areas."
  ),
  [InteriorFloorMaterialTypes.WOOD]: t(
    "Wood flooring offers timeless beauty and durability, available in options like hardwood, engineered wood, and laminate. It brings natural warmth to any interior."
  ),
  [InteriorFloorMaterialTypes.CONCRETE]: t(
    "Concrete flooring offers a modern, industrial aesthetic with high durability and low maintenance. It can be polished, stained, or textured for various looks."
  ),
  [InteriorFloorMaterialTypes.VINYL]: "",
  [InteriorFloorMaterialTypes.NO_MATERIAL]: "",
});

export const InteriorFloorMaterialConfigTexts: Config = {
  categoryName: (t) => t("Interior Floor Material"),
  itemName: (t) => t("Interior Floor Material"),
  categoryDescription: (t) =>
    t(
      "Interior floor materials are essential elements that contribute to a home's overall ambiance and usability. Choosing the right flooring balances aesthetics and function, as it complements the design and supports daily activities.  The large variety of options allows homeowners to tailor their choices to meet specific needs and preferences."
    ),
  itemNames,
  typeNames,
  typeDescriptions,
};
