import { useQuery } from "@tanstack/react-query";
import { fetchRequest } from "api/utils";
import { SECONDS } from "constants/time";
import { useMemo } from "react";
import { parseRetryAfter } from "shared/util/retryAfter";

interface ImageLoadError {
  code: number;
  retryAfter?: number;
}

const fetchImageAsBlobUrl = async (url: string) => {
  const response = await fetchRequest(url);
  const { status } = response;

  if (status === 429) {
    const retryAfter = parseRetryAfter(response);
    const err: ImageLoadError = {
      code: status,
      retryAfter,
    };
    throw err;
  }

  if (!response.ok) {
    const err: ImageLoadError = {
      code: status,
    };
    throw err;
  }

  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

const MAX_RETRIES = 10;

const retry = (failureCount: number, err: ImageLoadError) => {
  return err?.code === 429 && failureCount < MAX_RETRIES;
};

const retryDelay = (attempt: number, err: ImageLoadError) => {
  if (err?.code === 429 && err?.retryAfter) {
    return err.retryAfter;
  }

  return attempt * SECONDS;
};

export const useFetchImageAsBlobUrl = (props: {
  src: string;
  enabled: boolean;
}) => {
  const { src, enabled } = props;
  const queryKey = useMemo(() => ["imagePlaceholder", src], [src]);

  const {
    data: blobUrl,
    isLoading: isFetching,
    isError,
  } = useQuery({
    queryKey,
    queryFn: () => fetchImageAsBlobUrl(src),
    enabled: enabled && Boolean(src),
    retry,
    retryDelay,
  });

  return {
    blobUrl,
    isFetching,
    isError,
  };
};
