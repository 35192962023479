import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { ROOMS_PIECES_NAMES, RoomType } from "constants/rooms/roomPieces";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.ROOM,
  RoomType
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  ...ROOMS_PIECES_NAMES(t),
});

const typeNames: Config["typeNames"] = (t) => ({
  ...ROOMS_PIECES_NAMES(t),
});

export const RoomConfigTexts: Config = {
  categoryName: (t) => t("Room types"),
  itemName: (t) => t("Room types"),
  categoryDescription: (t) => t("Room types"),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
