import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { CeilingFanControlTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.CEILING_FAN_CONTROL,
  CeilingFanControlTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [CeilingFanControlTypes.ON_OFF_SPEED_CONTROL]: t("Speed Control"),
  [CeilingFanControlTypes.SWITCH]: t("Switch"),
  [CeilingFanControlTypes.CONTROL_PANEL]: t("Control Panel"),
  [CeilingFanControlTypes.PULL_CHORD]: t("Pull Chord"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [CeilingFanControlTypes.ON_OFF_SPEED_CONTROL]: t("Speed Control"),
  [CeilingFanControlTypes.SWITCH]: t("Switch"),
  [CeilingFanControlTypes.CONTROL_PANEL]: t("Control Panel"),
  [CeilingFanControlTypes.PULL_CHORD]: t("Pull Chord"),
});

const typeDescriptions: Config["typeDescriptions"] = (t) => ({
  [CeilingFanControlTypes.PULL_CHORD]:
    "A pull chord is a simple, reliable control mechanism that allows users to turn the fan on and off. It typically features a cord with a handle.",
  [CeilingFanControlTypes.SWITCH]:
    "Switch fan controls combine a simple on/off switch with a sliding mechanism to adjust the fan speed. This design allows easy control of both power and airflow.",
  [CeilingFanControlTypes.ON_OFF_SPEED_CONTROL]:
    "Rotary speed controls use a dial to adjust fan speed through preset levels, including an off position at one end. They provide a classic, tactile way to manage airflow.",
  [CeilingFanControlTypes.CONTROL_PANEL]:
    "Control panels offer advanced functionality with buttons or touchscreens to adjust fan speed, lighting, and other features. These panels provide a modern, all-in-one interface.",
});

export const CeilingFanControlsConfigTexts: Config = {
  categoryName: (t) => t("Ceiling Fan Controls"),
  itemName: (t) => t("Ceiling Fan Control"),
  categoryDescription: (t) =>
    t(
      "Fan controls provide convenient ways to adjust fan settings and airflow, enhancing the usability of ceiling fans and offering tailored comfort and efficiency according to individual preferences and changing environmental conditions."
    ),
  itemNames,
  typeNames,
  typeDescriptions,
};
