import { LinearProgress, LinearProgressClasses } from "@mui/material";
import clsx from "clsx";
import { smartTimeout } from "common/utils/testUtils";
import { ImagePlaceholder } from "components/shared/ImagePlaceholder/ImagePlaceholder";
import { PoweredByNook } from "integration/components/PoweredByNook/PoweredByNook";
import {
  EMBEDDED_GUIDE_PARTNER_CAPTION,
  EMBEDDED_GUIDE_PARTNER_LOGO,
} from "integration/TryItOut.embeded";
import { FC, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDidMount } from "rooks";

import styles from "./PartnerLogoAndCaptionLoader.module.scss";

const progressClasses: Partial<LinearProgressClasses> = {
  bar: styles.loaderBar,
};

function easeIn(t: number, b: number, c: number, d: number) {
  return c * (t /= d) * t + b;
}

export const PartnerLogoAndCaptionLoader: FC<{ onProceed: () => void }> = ({
  onProceed,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [progress, setProgress] = useState(4);
  const [isLogoReady, setIsLogoReady] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const handleSetFinished = useCallback(() => {
    smartTimeout(() => {
      setIsFinished(true);
    }, 1000);
  }, [setIsFinished]);

  const handleSetLogoReady = useCallback(() => {
    setIsLogoReady(true);
  }, [setIsLogoReady]);

  useDidMount(() => {
    smartTimeout(() => {
      setIsReady(true);
    }, 700);
  });

  useLayoutEffect(() => {
    if (isLogoReady) {
      smartTimeout(() => {
        const handleProgress = (time: number) => {
          const newProgress = easeIn(time, 4, 100, 5000);
          setProgress(() => newProgress);
          if (newProgress < 100) {
            requestAnimationFrame(handleProgress);
          }
        };
        const frame = requestAnimationFrame(handleProgress);
        return () => cancelAnimationFrame(frame);
      }, 800);
    }
  }, [isLogoReady]);

  useEffect(() => {
    if (isFinished) {
      smartTimeout(() => {
        onProceed();
      }, 700);
    }
  }, [isFinished, onProceed]);

  const Caption = EMBEDDED_GUIDE_PARTNER_CAPTION;

  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(
          styles.logoWrapper,
          isReady && styles.slideUp,
          isFinished && styles.slideDown
        )}
        onAnimationEnd={handleSetLogoReady}
      >
        <ImagePlaceholder
          src={EMBEDDED_GUIDE_PARTNER_LOGO.src}
          fallbackSrc={EMBEDDED_GUIDE_PARTNER_LOGO.fallbackSrc}
          className={styles.logo}
        />
        <LinearProgress
          variant="determinate"
          value={progress}
          className={styles.loader}
          classes={progressClasses}
        />
        <Caption
          className={clsx(
            styles.initial,
            progress >= 100 && styles.slideUpCaption
          )}
          onAnimationEnd={handleSetFinished}
        />
      </div>
      <PoweredByNook
        className={clsx(
          styles.initial,
          isReady && styles.fadeIn,
          isFinished && styles.fadeOut
        )}
      />
    </div>
  );
};
