import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { PackagedSystemTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.PACKAGED_SYSTEM,
  PackagedSystemTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [PackagedSystemTypes.DEFAULT]: t("Default"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [PackagedSystemTypes.DEFAULT]: t("Defaults"),
});

export const PackagedSystemConfigTexts: Config = {
  categoryName: (t) => t("Packaged Systems"),
  itemName: (t) => t("Packaged System"),
  categoryDescription: (t) =>
    t(
      "Packaged air conditioning systems are all-in-one units that combine cooling and heating components into a single, streamlined system. Their integrated design simplifies installation and maintenance, and they often offer higher energy efficiency and space-saving benefits compared to traditional split systems."
    ),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
