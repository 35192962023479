const url = (s: string) => s?.replace(/:80|:443/, "");

export const API_BASE = url(process.env.REACT_APP_BACKEND_ENDPOINT);
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const TWITTER_SITE = process.env.REACT_APP_TWITTER_SITE;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const SENTRY_DSN = url(process.env.REACT_APP_SENTRY_DSN);
export const SENTRY_SPOTLIGHT =
  process.env.REACT_APP_SENTRY_SPOTLIGHT === "true";
export const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION;
export const PAGEFIND_VERSION = process.env.REACT_APP_PAGEFIND_VERSION;
export const DEPLOYMENT_NAME = process.env.REACT_APP_DEPLOYMENT_NAME;
export const FILES_ENDPOINT = url(process.env.REACT_APP_FILES_ENDPOINT);
export const FRONTEND_ENDPOINT = url(process.env.REACT_APP_FRONTEND_ENDPOINT);

// NOTE(clemens): we deliberately hard-code the production origin here
//  instead of using REACT_APP_FRONTEND_ENDPOINT or window.location.origin
//  to protect against the scenario where due to some bug a crawler ends
//  up indexing a buddy build: we never want production to be penalized
//  for duplicate content that happens to be ingested by a crawler
export const CANONICAL_ORIGIN = "https://www.builtwithnook.com";
export const DEMO_ORIGIN = "https://demo.builtwithnook.com";

export const POSTHOG_ENDPOINT = url(process.env.REACT_APP_POSTHOG_ENDPOINT);
export const POSTHOG_KEY = {
  "www.builtwithnook.com": process.env.REACT_APP_POSTHOG_KEY_PROD,
  "demo.builtwithnook.com": process.env.REACT_APP_POSTHOG_KEY_PROD,
  "preview-www.homebase.llc": process.env.REACT_APP_POSTHOG_KEY_TEST,
}[window.location.hostname];

export const IS_STAGING = new URL(window.location.href).hostname.endsWith(
  ".homebase.llc"
);

export const IS_DEV_ENV = process.env.NODE_ENV === "development";

export const STRIPE_KEY =
  DEPLOYMENT_NAME === "homebasemain"
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST;

export const STRIPE_CUSTOMER_PORTAL_URL =
  DEPLOYMENT_NAME === "homebasemain"
    ? "https://billing.stripe.com/p/login/6oE6qBauy6yy0N2cMM"
    : "https://billing.stripe.com/p/login/test_dR6g1Y91Y6eY2WceUU";

const featureFlag = (name: string, enabledFor: Array<string | RegExp>) => {
  const url = new URL(window.location.href);
  if (
    enabledFor.some((it) =>
      typeof it === "string"
        ? url.hostname === it
        : url.hostname.match(it) != null
    )
  ) {
    return true;
  }

  if (IS_STAGING || ["localhost", "bs-local.com"].includes(url.hostname)) {
    const sessionKey = `homebase/${name}`;

    if (url.searchParams.get(name)) {
      window.sessionStorage.setItem(sessionKey, url.searchParams.get(name));
    }
    return window.sessionStorage.getItem(sessionKey) === "true";
  }
  return false;
};

export const ROOMS_AND_SPACES_ENABLED = featureFlag(
  "rooms_and_spaces_enabled",
  ["localhost"]
);

export const HIP_PRESETS_ENABLED = featureFlag("hip_presets_enabled", []);

export const SUBSCRIPTIONS_ENABLED = featureFlag("subscriptions_enabled", [
  "localhost",
]);

export const HIP_BOOKS_ENABLED = featureFlag("hip_books_enabled", [
  "localhost",
]);

export const WINDOWS_AND_DOORS_GUIDE_ENABLED = featureFlag(
  "windows_and_doors_guide_enabled",
  ["localhost"]
);

export const KITCHEN_GUIDES_ENABLED = featureFlag("kitchen_guides_enabled", [
  "localhost",
]);

export const GUIDES_ENABLED_FOR_IPAD = featureFlag("guides_enabled_for_ipad", [
  "localhost",
]);

export const VERIFICATION_DOCUMENTS_ENABLED = false;

export const ABOUT_ENABLED = false;

export const HELP_CENTER_ENABLED = false;

export const INSPIRATION_ENABLED = false;

export const BLOG_INSPIRATION_ENABLED = true;

export const HIDE_EXTRA_ATTOM_DATA = true;

export const HIDE_HIP_TITLE_SUGGESTIONS = true;

export const SOCIAL_LOGIN_ENABLED = false;

export const SMALL_FOOTER_ENABLED = true;

export const SENTRY_REPLAY_DISABLED = {
  "www.builtwithnook.com": true,
  "demo.builtwithnook.com": true,
}[window.location.hostname];

export const IS_TRY_IT_OUT_ENABLED = featureFlag("is_try_it_out_enabled", [
  "demo.builtwithnook.com",
]);
