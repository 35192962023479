import { CoverPhotos } from "api/models/CoverPhotos";
import { useOrchestratorRoomsMap } from "core/state/hooks/useOrchestratorRoomsAndSpaces";
import { COMMON_TAG_CATEGORIES } from "pages/Media/constants";
import { FiltersView } from "pages/Media/types";
import { RoomAndSpace } from "pages/RoomsAndSpaces/RoomsAndSpaces.types";
import { TFunction } from "react-i18next";
import { SvgIconComponent } from "shared/types/SvgIcon";
import { getKeys } from "shared/util/getKeys";

import { ROOMS_CATEGORIES, RoomsCategories } from "./roomCategories";
import { ROOMS_PIECES_ICONS } from "./roomIcons";
import {
  ROOMS_PIECES_NAMES,
  ROOMS_PIECES_ORDERS,
  ROOMS_PIECES_VALUES,
  RoomType,
} from "./roomPieces";

const roomComparator = (a: RoomAndSpace, b: RoomAndSpace) => {
  const orderA = ROOMS_PIECES_ORDERS[a.type];
  const orderB = ROOMS_PIECES_ORDERS[b.type];
  const isSimilarOrder = orderA === orderB;
  return isSimilarOrder
    ? a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    : orderA - orderB;
};

export const getRoomIcon = (type: RoomType): SvgIconComponent =>
  ROOMS_PIECES_ICONS[type];

export const getPlanningRoomsList = (rooms: RoomAndSpace[]) => {
  if (!rooms) {
    return [];
  }
  return [...rooms].sort(roomComparator);
};

export const getRoomCategoryForRoomType = (type: RoomType): RoomsCategories => {
  return getKeys(ROOMS_CATEGORIES).find((category) =>
    ROOMS_CATEGORIES[category].includes(type)
  );
};

export const isExteriorSpace = (type: RoomType): boolean => {
  return ROOMS_CATEGORIES.exterior.some((item) => item === type);
};

type RoomFilterView = {
  id: RoomType;
  category: COMMON_TAG_CATEGORIES.ROOMS_AND_SPACES;
  icon: JSX.Element;
  text: string;
  order: number;
};

export const getProRoomsFilterView = (
  t: TFunction,
  roomTags: Record<string, number> = undefined
) => {
  return ROOMS_PIECES_VALUES.reduce((result, roomType) => {
    const Icon = getRoomIcon(roomType);
    if (!roomTags || roomTags[roomType]) {
      result.push({
        id: roomType,
        category: COMMON_TAG_CATEGORIES.ROOMS_AND_SPACES,
        icon: <Icon />,
        text: ROOMS_PIECES_NAMES(t)[roomType],
        order: ROOMS_PIECES_ORDERS[roomType],
      });
    }
    return result;
  }, [] as RoomFilterView[]);
};

export const getRoomsView = (
  roomsMap: ReturnType<typeof useOrchestratorRoomsMap>,
  roomsIds: string[],
  coverPhotos: CoverPhotos
) => {
  const roomsView = roomsIds.reduce<FiltersView[]>((result, roomId) => {
    const room = roomsMap[roomId];
    if (room) {
      const Icon = getRoomIcon(room.type);

      result.push({
        id: roomId,
        category: COMMON_TAG_CATEGORIES.ROOMS_AND_SPACES,
        text: room.name,
        room,
        order: ROOMS_PIECES_ORDERS[room.type],
        icon: <Icon />,
        thumbnailUrl: coverPhotos[room.id]?.thumbnail_url,
        thumbnailUrlFallback: coverPhotos[room.id]?.thumbnail_url_fallback,
        thumbnailImageId: coverPhotos[room.id]?.id,
      });
    }
    return result;
  }, []);

  return sortRoomsFilterView(roomsView);
};

const getRoomTypesCountMap = (rooms: RoomAndSpace[]) => {
  return rooms.reduce<Record<RoomType, number>>(
    (result, room) => ({
      ...result,
      [room.type]: result[room.type] ? result[room.type] + 1 : 1,
    }),
    {} as Record<RoomType, number>
  );
};

export const getUpdatedRoomNames = (t: TFunction, rooms: RoomAndSpace[]) => {
  const roomTypesCountMap = getRoomTypesCountMap(rooms);
  const updateTypesCountMap = {};
  return rooms.map((room) => {
    if (!updateTypesCountMap[room.type]) {
      updateTypesCountMap[room.type] = 0;
    }
    const rawName = ROOMS_PIECES_NAMES(t)[room.type];
    let roomName = room.name;
    if (roomTypesCountMap[room.type] === 1) {
      roomName = rawName;
    } else {
      updateTypesCountMap[room.type]++;
      const index = updateTypesCountMap[room.type];
      roomName = `${rawName} ${index}`;
    }
    return {
      ...room,
      name: roomName,
    };
  });
};

const sortRoomsFilterView = (rooms: FiltersView[]) => {
  return rooms.sort((a, b) => roomComparator(a.room, b.room));
};
