import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { SplitSystemTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SPLIT_SYSTEM,
  SplitSystemTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [SplitSystemTypes.DEFAULT]: t("Default"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [SplitSystemTypes.DEFAULT]: t("Defaults"),
});

export const SplitSystemConfigTexts: Config = {
  categoryName: (t) => t("Split Systems"),
  itemName: (t) => t("Split System"),
  categoryDescription: (t) =>
    t(
      "Split air conditioning systems consist of separate indoor and outdoor units. The indoor unit contains the evaporator coil for cooling or heating, while the outdoor unit houses the compressor and condenser.  These systems are commonly used for cooling individual rooms or zones in a home. Installation can be more complex than packaged systems, but they offer flexibility in terms of placement and zoning."
    ),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
