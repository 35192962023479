import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { FurnaceTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.FURNACE,
  FurnaceTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [FurnaceTypes.DEFAULT]: t("Default"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [FurnaceTypes.DEFAULT]: t("Defaults"),
});

export const FurnaceConfigTexts: Config = {
  categoryName: (t) => t("Furnace"),
  itemName: (t) => t("Furnace"),
  categoryDescription: (t) =>
    t(
      "A central heating unit used to warm buildings during colder months, a furnace works by drawing in cold air, heating it via a fuel source like natural gas, oil, or electricity, and then distributing the warmed air throughout the building using a blower. Modern furnaces are designed for efficiency and can effectively maintain consistent indoor temperatures, making them a staple in many homes in regions with colder climates."
    ),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
