import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { DoorCasingTrimTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.DOOR_CASING_TRIM,
  DoorCasingTrimTypes
>["texts"];

//TODO: Fill me

const itemNames: Config["itemNames"] = (t) => ({
  [DoorCasingTrimTypes.PLACEHOLDER]: t("Placeholder"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [DoorCasingTrimTypes.PLACEHOLDER]: t("Placeholder"),
});

export const DoorCasingTrimConfigTexts: Config = {
  categoryName: (t) => t("placeholder"),
  itemName: (t) => t("placeholder"),
  categoryDescription: (t) => t("placeholder"),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
