import { CircularProgress } from "@mui/material";
import { ReactComponent as ImageIcon } from "assets/icons/cover_image.svg";
import clsx from "clsx";
import { useDelayed } from "common/hooks/useDelayed";
import { useFetchImageAsBlobUrl } from "common/hooks/useFetchImageAsBlobUrl";
import { useImageLoaded } from "common/hooks/useImageLoaded";
import { HBImage } from "components/HBImage/HBImage";
import { Skeleton } from "components/shared/Skeleton/Skeleton";
import {
  ForwardedRef,
  forwardRef,
  ImgHTMLAttributes,
  isValidElement,
  SyntheticEvent,
  useCallback,
} from "react";
import { SvgIconComponent } from "shared/types/SvgIcon";

import styles from "./ImagePlaceholder.module.scss";

interface CommonProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string | undefined;
  fallbackSrc: string | undefined;
  onLoad?: (e: SyntheticEvent<HTMLImageElement, Event>) => void;
  onError?: (e: SyntheticEvent<HTMLImageElement, Event>) => void;
  loadingWhileEmpty?: boolean;
  showLoading?: boolean;
  loadingType?: "circular" | "skeleton";
  noImageAbsolutePosition?: boolean;
  shouldLoadAsBlob?: boolean;
  skeletonClassName?: string;
  className?: string;
  emptyClassName?: string;
  imageClassName?: string;
  imageWrapperClassName?: string;
  "data-testid"?: string;
  "data-testvalue"?: string;
  "data-testselected"?: string;
}

interface SvgProps {
  Icon?: SvgIconComponent;
  iconClassName?: string;
}

interface JsxProps {
  Icon?: JSX.Element;
  iconClassName?: never;
}

type Props = CommonProps & (SvgProps | JsxProps);

const isSVGComponent = (
  Icon?: SvgIconComponent | JSX.Element
): Icon is SvgIconComponent => {
  return !isValidElement(Icon);
};

const ImagePlaceholderComponent = (
  {
    src,
    fallbackSrc,
    className,
    onLoad,
    onError,
    showLoading = true,
    loadingWhileEmpty = false,
    loadingType = "circular",
    skeletonClassName,
    emptyClassName,
    imageClassName,
    noImageAbsolutePosition,
    shouldLoadAsBlob,
    imageWrapperClassName,
    Icon = ImageIcon,
    "data-testid": DTI = "ImagePlaceholder",
    "data-testselected": DTS,
    "data-testvalue": DTV,
    iconClassName,
    ...rest
  }: Props,
  ref: ForwardedRef<HTMLImageElement>
) => {
  const { blobUrl, isFetching, isError } = useFetchImageAsBlobUrl({
    src,
    enabled: shouldLoadAsBlob,
  });

  const resolvedSrc =
    !src || (isError && fallbackSrc)
      ? fallbackSrc
      : shouldLoadAsBlob
      ? blobUrl
      : src;

  const { imageError, imageLoading, handleImageError, handleImageLoaded } =
    useImageLoaded(resolvedSrc);

  const handleLoad = useCallback(
    (e: SyntheticEvent<HTMLImageElement, Event>) => {
      handleImageLoaded();
      onLoad?.(e);
    },
    [onLoad, handleImageLoaded]
  );

  const handleError = useCallback(
    (e: SyntheticEvent<HTMLImageElement, Event>) => {
      handleImageError();
      onError?.(e);
    },
    [onError, handleImageError]
  );

  const _hasLoading =
    showLoading &&
    ((isFetching && !blobUrl) ||
      imageLoading ||
      (!resolvedSrc && loadingWhileEmpty));

  const hasLoading = useDelayed(_hasLoading, 250);

  const hasImg = resolvedSrc && !imageError;
  const isEmpty = (!hasImg || imageError) && !hasLoading;

  const noImageIcon = isSVGComponent(Icon) ? (
    <Icon
      className={clsx(
        styles.placeholder__icon,
        noImageAbsolutePosition && styles.absolute,
        iconClassName
      )}
      width={24}
      height={24}
    />
  ) : (
    Icon
  );

  return (
    <div
      className={clsx(
        styles.placeholder,
        isEmpty && [styles.placeholder__empty, emptyClassName],
        className
      )}
    >
      {hasLoading &&
        (loadingType === "circular" ? (
          <div className={styles.placeholder__loadingWrapper}>
            <CircularProgress />
          </div>
        ) : (
          <Skeleton className={skeletonClassName} />
        ))}

      {isEmpty && noImageIcon}

      {hasImg && (
        <HBImage
          webpSrc={resolvedSrc}
          fallback={fallbackSrc}
          className={imageClassName}
          wrapperClassName={clsx(imageWrapperClassName, {
            [styles.hideImage]: imageLoading,
          })}
          alt=""
          onLoad={handleLoad}
          onError={handleError}
          data-testid={DTI}
          data-testvalue={DTV}
          data-testselected={DTS}
          ref={ref}
          {...rest}
        />
      )}
    </div>
  );
};

export const ImagePlaceholder = forwardRef(ImagePlaceholderComponent);
