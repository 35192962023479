import { FloorPlanItem } from "pages/Guides/components/FloorPlan/FloorPlanItems/FloorPlanItems.types";
import { RestrictPositionRuleFn } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.types";
import { GuideIntentType } from "pages/Guides/enums";
import { TFunction } from "react-i18next";

import {
  GetGuideCategoryItemTypes,
  GetGuideItemCategoryByType,
  GuideItemCategoriesMap,
  GuideItemCategoriesMapType,
  GuideItemsTypes,
} from "./GuideItems";
import { GuideItemTypeCategory } from "./GuideItems.enums";
import {
  AbstractGuideItem,
  GuideItem,
  GuideItemTypeConfig,
  RuleType,
} from "./GuideItems.types";

export const isAbstractGuideItem = (arg: unknown): arg is AbstractGuideItem =>
  Boolean(arg && typeof arg === "object" && "category" in arg);

export const isGuideItem = (arg: unknown): arg is GuideItem =>
  Boolean(isAbstractGuideItem(arg) && "coords" in arg);

export const ReversedGuideItemCategoriesMap = ((
  initialMap: GuideItemCategoriesMapType
) => {
  const reversedMap: Partial<{
    [Type in GuideItemsTypes]: GuideItemTypeConfig<
      GetGuideItemCategoryByType<Type>,
      GetGuideCategoryItemTypes<GetGuideItemCategoryByType<Type>>
    >;
  }> = {};
  const keys = Object.keys(initialMap);

  for (const key of keys) {
    const value = initialMap[key as GuideItemsTypes];

    for (const type of value.types) {
      reversedMap[type] = value;
    }
  }

  return reversedMap as Required<typeof reversedMap>;
})(GuideItemCategoriesMap);

const getGuideItemCategoryConfigByType = <
  Type extends GuideItemsTypes,
  Category extends GuideItemTypeCategory = GetGuideItemCategoryByType<Type>
>(
  type: Type
) => {
  const config = ReversedGuideItemCategoriesMap[type];

  if (!config) {
    throw new Error(`Cannot find config with for [${type}]`);
  }

  return config as unknown as GuideItemTypeConfig<
    Category,
    GetGuideCategoryItemTypes<Category>
  >;
};

export const getGuideItemCategoryConfigByCategory = <
  Category extends GuideItemTypeCategory
>(
  category: Category
) => {
  const config = GuideItemCategoriesMap[category];

  if (!config) {
    throw new Error(`Cannot find config with for [${category}]`);
  }

  return config as unknown as GuideItemTypeConfig<
    Category,
    GetGuideCategoryItemTypes<Category>
  >;
};

export const getGuideItemGuideType = <Category extends GuideItemTypeCategory>(
  category: Category
) => {
  const config = getGuideItemCategoryConfigByCategory<Category>(category);

  return config.guideType;
};

export const getGuideItemIcon = <Type extends GuideItemsTypes>(type: Type) => {
  const config = getGuideItemCategoryConfigByType<Type>(type);

  if (config.images.icons) {
    return config.images.icons[type as keyof typeof config.images.icons];
  } else {
    return undefined;
  }
};

export const getGuideItemSymbol = <Type extends GuideItemsTypes>(
  type: Type
) => {
  const config = getGuideItemCategoryConfigByType<Type>(type);

  return config.images.symbols
    ? config.images.symbols[type as keyof typeof config.images.symbols]
    : undefined;
};

export const getGuideItemModifiedIcon = <Type extends GuideItemsTypes>(
  type: Type,
  state: string
) => {
  const config = getGuideItemCategoryConfigByType<Type>(type);

  return config.images.modifiedIcons[state];
};

export const getGuideItemJSXIcon = (type: GuideItemsTypes) => {
  const ItemIcon = getGuideItemIcon(type);

  return <ItemIcon />;
};

const getGuideItemCustomRender = <Type extends GuideItemsTypes>(type: Type) => {
  const config = getGuideItemCategoryConfigByType<Type>(type);
  return config.images.render ? config.images.render : undefined;
};

// TODO(pavel): remove eslint-disable once it's used
// eslint-disable-next-line import/no-unused-modules
export const getGuideItemDefaultIcon = <Type extends GuideItemsTypes>(
  type: Type
) => {
  const config = getGuideItemCategoryConfigByType<Type>(type);

  return config.images.defaultIcon;
};

export const getGuideItemImage = <Type extends GuideItemsTypes>(type: Type) => {
  const config = getGuideItemCategoryConfigByType<Type>(type);

  return config.images.images[type as keyof typeof config.images.images].src;
};

export const getGuideItemFallbackImage = <Type extends GuideItemsTypes>(
  type: Type
) => {
  const config = getGuideItemCategoryConfigByType<Type>(type);

  return config.images.images[type as keyof typeof config.images.images]
    .fallbackSrc;
};

const getGuideItemDefaultFloorPlanSize = <Type extends GuideItemsTypes>(
  type: Type
) => {
  const config = getGuideItemCategoryConfigByType<Type>(type);

  return config.floorPlan.sizes[type as keyof typeof config.floorPlan.sizes];
};

const getGuideItemDefaultFloorPlanRule = <Type extends GuideItemsTypes>(
  type: Type
): RestrictPositionRuleFn | undefined => {
  const config = getGuideItemCategoryConfigByType<Type>(type);
  const rulesMap = config.floorPlan.rules;

  return rulesMap
    ? rulesMap[type as keyof typeof config.floorPlan.rules]
    : undefined;
};

export const getGuideItemDefaultRuleSet = <Type extends GuideItemsTypes>(
  type: Type
): RuleType[] => {
  const config = getGuideItemCategoryConfigByType<Type>(type);
  const rulesSets = config.floorPlan.ruleSets;

  return rulesSets?.[type as keyof typeof config.floorPlan.rules] ?? [];
};

export const getGuideItemCategoryItemTypes = <
  Category extends GuideItemTypeCategory
>(
  category: Category
) => {
  const config = getGuideItemCategoryConfigByCategory(category);

  return config.types;
};

// TODO(pavel): remove eslint-disable once it's used
// eslint-disable-next-line import/no-unused-modules
export const getGuideItemTypesOrder = <Type extends GuideItemsTypes>(
  type: Type
) => {
  const config = getGuideItemCategoryConfigByType<Type>(type);

  return config.typesOrder;
};

export const getGuideItemName = <Type extends GuideItemsTypes>(
  t: TFunction,
  type: Type
) => {
  const config = getGuideItemCategoryConfigByType<Type>(type);

  return config.texts.itemNames(t)[type as keyof typeof config.texts.itemNames];
};

export const getGuideItemDescription = <Type extends GuideItemsTypes>(
  t: TFunction,
  type: Type
) => {
  const config = getGuideItemCategoryConfigByType<Type>(type);

  const descriptions = config.texts.typeDescriptions;

  if (!descriptions) {
    return undefined;
  }

  return descriptions(t)[type as keyof typeof config.texts.typeDescriptions];
};

export const getGuideItemTypeName = <Type extends GuideItemsTypes>(
  t: TFunction,
  type: Type
) => {
  const config = getGuideItemCategoryConfigByType<Type>(type);

  return config.texts.typeNames(t)[type as keyof typeof config.texts.typeNames];
};

export const getGuideItemCategoryName = <
  Category extends GuideItemTypeCategory
>(
  t: TFunction,
  category: Category
) => {
  const config = getGuideItemCategoryConfigByCategory(category);

  return config.texts.categoryName(t);
};

export const getGuideItemGeneralName = <Category extends GuideItemTypeCategory>(
  t: TFunction,
  category: Category
) => {
  const config = getGuideItemCategoryConfigByCategory(category);

  return config.texts.itemName(t);
};

export const getGuideItemCategoryIcon = <
  Category extends GuideItemTypeCategory
>(
  category: Category
) => {
  const config = getGuideItemCategoryConfigByCategory(category);

  return config.images.categoryIcon;
};

export const getGuideItemDefaultImage = <
  Category extends GuideItemTypeCategory
>(
  category: Category
) => {
  const config = getGuideItemCategoryConfigByCategory(category);

  return config.images.defaultImage;
};

export const getGuideItemCategoryDescription = <
  Category extends GuideItemTypeCategory
>(
  t: TFunction,
  category: Category
) => {
  const config = getGuideItemCategoryConfigByCategory(category);

  return config.texts.categoryDescription(t);
};

interface GetNextGuideItemIndexParams<T extends AbstractGuideItem> {
  existingItems: T[];
  category?: GuideItemTypeCategory;
  roomId?: string;
}

export const getNextGuideItemIndex = <T extends AbstractGuideItem>(
  params: GetNextGuideItemIndexParams<T>
) => {
  const { existingItems, roomId, category } = params;
  let itemsList = [...existingItems];

  if (roomId !== undefined) {
    itemsList = existingItems.filter((item) => item.roomId === roomId);
  }

  if (category) {
    itemsList = existingItems.filter((item) => item.category === category);
  }

  if (itemsList.length === 0) {
    return 1;
  }

  const sorted = itemsList.sort((a, b) => a.index - b.index);

  for (let i = 0; i < sorted.length; i++) {
    const item = sorted[i];
    const nextItem = sorted[i + 1];

    if (i === 0 && item.index > 1) {
      return 1;
    }

    if (nextItem && nextItem.index - item.index > 1) {
      return item.index + 1;
    }
  }

  return sorted[sorted.length - 1].index + 1;
};

export const toFloorPlanItem = (
  item: GuideItem,
  intent?: GuideIntentType | GuideIntentType[]
): FloorPlanItem<GuideItem> => {
  const intentsList = Array.isArray(intent) ? intent : [intent];

  return {
    item: { ...item },
    size: getGuideItemDefaultFloorPlanSize(item.type),
    rules: getGuideItemDefaultFloorPlanRule(item.type),
    ruleSets: getGuideItemDefaultRuleSet(item.type),
    render: getGuideItemCustomRender(item.type),
    intents: intentsList.filter(Boolean),
  };
};

export const createGuideSubtypeTexts = <T extends Record<string, string>>(
  subtype: T,
  getText: (item: keyof T) => (t: TFunction) => string
): Record<keyof T, (t: TFunction) => string> => {
  return Object.keys(subtype).reduce((acc, key) => {
    const typedKey = key as keyof T;
    return {
      ...acc,
      [typedKey]: getText(typedKey),
    };
  }, {} as Record<keyof T, (t: TFunction) => string>);
};
