import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { FloorPlanShapes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SHAPES,
  FloorPlanShapes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [FloorPlanShapes.SQUARE]: t("Square"),
  [FloorPlanShapes.RECTANGLE]: t("Rectangle"),
  [FloorPlanShapes.L_SHAPED]: t("L Shaped"),
  [FloorPlanShapes.T_SHAPED]: t("T Shaped"),
  [FloorPlanShapes.U_SHAPED]: t("U Shaped"),
  [FloorPlanShapes.CUSTOM]: t("Custom"),
});
const typeNames: Config["typeNames"] = (t) => ({
  [FloorPlanShapes.SQUARE]: t("Square"),
  [FloorPlanShapes.RECTANGLE]: t("Rectangle"),
  [FloorPlanShapes.L_SHAPED]: t("L Shaped"),
  [FloorPlanShapes.T_SHAPED]: t("T Shaped"),
  [FloorPlanShapes.U_SHAPED]: t("U Shaped"),
  [FloorPlanShapes.CUSTOM]: t("Custom (Draw Myself)"),
});

export const ShapesConfigTexts: Config = {
  categoryName: (t) => t("Making a Floor Plan"),
  itemName: (t) => t("Making a Floor Plan"),
  categoryDescription: (t) => t("Making a Floor Plan"),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
