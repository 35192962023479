import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import {
  AdjustmentTypes,
  ShapeAdjustmentTypes,
  WallAdjustmentTypes,
} from "./types";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.ADJUSTMENTS,
  AdjustmentTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [ShapeAdjustmentTypes.PULL_WALL]: t("Pull a section of wall in"),
  [ShapeAdjustmentTypes.PUSH_WALL]: t("Push a section of wall out"),
  [ShapeAdjustmentTypes.FLATTEN_CORNER]: t("Flatten corner"),
  [ShapeAdjustmentTypes.UN_FLATTEN_CORNER]: t("Un-Flatten corner"),
  [ShapeAdjustmentTypes.ROUND_CORNER]: t("Round corner"),
  [ShapeAdjustmentTypes.UN_ROUND_CORNER]: t("Un-Round corner"),
  [WallAdjustmentTypes.REMOVE_WALL_OR_SECTION]: t("Remove a Wall or Section"),
  [WallAdjustmentTypes.PLACE_SHORT_WALL]: t("Add a Short Wall"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [ShapeAdjustmentTypes.PULL_WALL]: t("Pull a section of wall in"),
  [ShapeAdjustmentTypes.PUSH_WALL]: t("Push a section of wall out"),
  [ShapeAdjustmentTypes.FLATTEN_CORNER]: t("Flatten corner"),
  [ShapeAdjustmentTypes.UN_FLATTEN_CORNER]: t("Un-Flatten corner"),
  [ShapeAdjustmentTypes.ROUND_CORNER]: t("Round corner"),
  [ShapeAdjustmentTypes.UN_ROUND_CORNER]: t("Un-Round corner"),
  [WallAdjustmentTypes.REMOVE_WALL_OR_SECTION]: t("Remove a Wall or Section"),
  [WallAdjustmentTypes.PLACE_SHORT_WALL]: t("Add a Short Wall"),
});

export const AdjustmentConfigTexts: Config = {
  categoryName: (t) => t("Making a Floor Plan"),
  itemName: (t) => t("Making a Floor Plan"),
  categoryDescription: (t) => t("Making a Floor Plan"),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
